@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
* {
  padding: 0px;
  margin: 0px;
}
.links-sidebar a {
  width: 216px !important;
  margin: 0px 10px;
  border-radius: 8px;
  padding: 6px 24px 6px 16px;
  min-height: auto !important;
}
.links-sidebar .Mui-selected {
  background: #da291c !important;
  color: #fff !important;
}
.links-sidebar a:hover {
  background-color: #ffffff;
}
.links-sidebar .MuiButtonBase-root .MuiListItemIcon-root svg {
  color: #da291c;
}
.header-section-sidebar {
  align-items: center !important;
  justify-content: center !important;
  min-height: 65px !important;
  border-bottom: 1px solid #DADADC;
}
.links-sidebar .css-1m5i5w0-MuiButtonBase-root-MuiListItemButton-root.Mui-selected:hover {
  background-color: #da291c !important;
  color: #ffffff !important;
  width: 216px !important;
}
.links-sidebar .MuiButtonBase-root .MuiListItemIcon-root {
  margin-right: 7px !important;
  width: 30px;
  height: 20px;
}
.logo-image-of-the-project {
  width: 105px;
  height: 55px;
}
.all-sidebar-listitems {
  display: grid;
  gap: 4px;
  padding-top: 20px;
}
.sidebar-scrolling {
  overflow-y: scroll;
  height: 60vh;
  overflow-x: hidden;
}
.all-sidebar-listitems .MuiList-padding.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.links-sidebar .MuiListItemText-primary {
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 15px;
  line-height: 20.36px;
}
.links-sidebar .css-wa5fuu-MuiListItemIcon-root {
  margin-right: 10px;
}
.Toastify {
  z-index: 999999;
  position: relative;
}
.main-header {
  box-shadow: none !important;
  border-bottom: 1px solid #dedfe5;
  background-color: #ffffff !important;
}
.main-header {
  z-index: 999 !important;
}
.bellicon-in-header {
  height: 20px !important;
  width: 20px !important;
  color: #da291c;
}
.bellicon-profileimage {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  width: 100%;
}
.bell-icon-container span {
  position: absolute;
  background: #da291c;
  height: 16px;
  width: 16px;
  text-align: center;
  line-height: 17px;
  font-size: 11px;
  font-weight: 600;
  border-radius: 50%;
  top: 8px;
  right: 7px;
}
.Siderbarresponsivebutton {
  display: none !important;
}
.outer-bellicon-in-header-container {
  background-color: #f5f6fa;
  margin-right: 15px;
  border-radius: 30px;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vertical-line {
  border: 1px solid #dedfe5;
  height: 40px;
}
.userprofile-image {
  margin: 0px 12px;
  height: 40px;
  width: 40px;
  border-radius: 50% !important;
}
.user-name-header {
  font-size: 16px;
  font-weight: 700;
  font-family: "Open Sans";
  line-height: 16.94px;
  color: #101820;
}
.user-name-header-designation {
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans";
  line-height: 16.94px;
  color: #101820;
}
.userrole-header {
  font-size: 13px;
  font-weight: 500;
  font-family: Inter;
  line-height: 15.73px;
  color: #101820;
  opacity: 0.5;
}
.image-and-information {
  display: flex;
  padding: 0 16px;
  border-left: 1px solid #eee;
}
.username-and-role {
  margin: 0px 8px;
  text-align: justify;
  display: grid;
  align-items: center;
}
.down-arrow-header {
  color: #da291c;
}
.kljdf {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.classnametest {
  color: red !important;
}
.kljcxzfghioiudf {
  font-size: 100px;
  color: red !important;
}
.toolbar-header {
  justify-content: space-between;
}
.outer-down-arrow-header-container {
  display: flex;
  align-items: center;
}
.links-sidebar .css-1m5i5w0-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
  width: 216px !important;
}
.css-piqts5-MuiInputBase-root {
  opacity: 0.5;
  color: #202224;
}
.main-header {
  height: 65px !important;
}
.search-inner-text .css-yz9k0d-MuiInputBase-input::placeholder {
  color: #202224 !important;
}
.css-zxdg2z {
  background-color: #f3f4f9;
}
.main-sidebar-pages-and-addsearch-data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 10px;
}
.backarrow-header-main {
  width: 44px;
  height: 44px;
  border-radius: 5px;
  border: 1px solid #DEDFE5;
  background-color: #F4F5F9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.backarrow-list-button {
  padding: 0px !important;
  border-radius: 5px !important;
}
.arrowbutton-item-list {
  padding-left: 0px !important;
}
.Leades-filter-inner-container-header button {
  color: red !important;
  background-color: #ffffff !important;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  padding: 0px !important;
  text-transform: none;
  display: flex;
  justify-content: center;
  text-transform: none;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
}
.Leades-filter-inner-container-header button:hover {
  background-color: #ffffff !important;
  font-family: Inter;
}
.Leades-filter-inner-container-header {
  height: 40px !important;
  display: flex;
  align-items: center;
}
.AI-Leads-button-drop-down-up-header {
  line-height: 16px !important;
  color: #101820 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  padding: 0px 22px !important;
  display: flex !important;
  justify-content: flex-start !important;
  font-family: Inter !important;
  border-bottom: 0.5px solid #dedfe5 !important;
  height: 49px !important;
}
.AI-Leads-button-drop-down-up-header:hover {
  background-color: #ffffff !important;
}
.css-9tj150-MuiButton-endIcon {
  display: inherit !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.ewfwefe {
  width: 140px !important;
  min-width: 120px !important;
}
.training-cap-icon {
  width: 23px;
}
.custom-menu .MuiPaper-root,
.custom-menu .MuiPaper-elevation,
.custom-menu .MuiPaper-rounded,
.custom-menu .MuiPaper-elevation0,
.custom-menu .MuiPopover-paper,
.custom-menu .MuiMenu-paper,
.custom-menu .MuiMenu-paper,
.custom-menu .css-1x7jfmm-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  width: fit-content !important;
  min-width: 100px !important;
}
.bellicon-image {
  height: 19px;
  width: 17px;
}
.bell-icon-container {
  position: relative;
  margin-right: 35px;
  border: 1px solid #DEDEDE;
  height: 45px;
  width: 45px;
  padding: 15px 10px 10px 10px;
  border-radius: 50%;
  background: #FAFAFC;
  cursor: pointer;
}
.head-drop-menu img {
  height: 18px;
  margin-top: 5px;
}
.head-drop-menu {
  padding-right: 13px;
}
.outer_circle_sidebar {
  background: #fff;
  border-radius: 50%;
  height: 27px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.links-sidebar .Mui-selected div .outer_circle_sidebar_icon {
  background: #fff;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bell-icon-box {
  position: absolute;
  background: #fff;
  box-shadow: 0px 0px 2px 0px #00000033;
  border-radius: 5px;
  padding: 15px;
  top: 50px;
  /* right: 30px; */
  width: 450px;
}
.bell-icon-container-inner {
  position: relative;
  border: 1px solid #DEDEDE;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background: #FAFAFC;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bell-icon-outer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  width: 100%;
  padding-bottom: 15px;
}
.bellicon-p {
  color: #666666;
  font-size: 15px;
}
.bellicon-p2 {
  color: #000000;
  font-size: 15px;
}
.bellicon-cross {
  cursor: pointer;
  color: #6666666e;
  font-size: 20px !important;
}
/* .bellicon-separator {
  height: 1px;
  background: #6666662e;
  margin: 15px 0px;
} */
.bellicons-notification-container{
  border-bottom: 1px solid #6666666e ;
  margin-bottom: 15px;
}
.bellicons-notification-container:last-child{
  border-bottom:0px;
  margin-bottom: 0px;
}
.bellicons-notification-container:last-child .bell-icon-outer{
  padding-bottom: 0px;
}
.bellicon-text {
  flex: 1
}
.notification-count {
  color: #FFFFFF;
  background: #DA291C;
  border-radius: 30px;
  width: 15px;
  height: 15px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  position: absolute;
  left: 17px;
  top: 2px;
}
.bellicon-pos {
  position: relative;
  cursor: pointer;
  width: 40px;
  background-color: #F5F6FA;
  margin-right: 25px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}
.header-user-name {
  color: #003057;
  width: 46px;
  height: 46px;
  background-color: #f5f6fa;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}
.help-center-container {
  width: 216px !important;
  margin: 0px 10px !important;
  border-radius: 8px !important;
  padding: 6px 24px 6px 16px !important;
  min-height: auto !important;
}
.MuiList-root .MuiMenuItem-root:last-child {
  border: none !important;
}
.mobileresponsiveMaincsss {
 min-height: 90vh;
}
.videoIcon{
  width: 30px;
  cursor: pointer;
}
.video-space_inner{
  display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.video_profile{
  position: absolute !important;
  right: 245px;
  top: 20px;
  z-index: 1;
}
@media screen and (max-width: 767px) and (min-width: 320px) {
  .main-header {
    margin-left: 0px !important;
    padding: 0px !important;
    width: 100% !important;
  }
  .Siderbarresponsivebutton svg {
    color: #da291c;
  }
  .bellicon-profileimage {
    display: flex;
    height: 40px;
    width: unset;
    justify-content: flex-end;
  }
  .headermobileviewtogglebutton svg {
    color: red;
  }
  .logo-image-of-the-project {
    width: 70px;
    height: 44px;
  }
  .links-sidebar a {
    width: 1px !important;
    margin: 0px 10px;
    height: 1px;
    border-radius: 8px;
    padding: 23px;
  }
  .Siderbarresponsivebutton {
    display: block !important;
  }
  .mobileresponsiveMaincsss {
    width: 60% !important;
    position: absolute !important;
  }
  .mobileresponsiveMaincsss .MuiPaper-root {
    width: auto !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .mobileresponsiveMaincsssnew {
    width: 0% !important;
  }
  .mobileresponsiveMaincsssnew .MuiPaper-root {
    width: 0% !important;
  }
  .toolbar-header {
    justify-content: space-around !important;
  }
  .username-and-role {
    margin: 0 !important;
  }
  .main-headernew {
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    box-shadow: none !important;
    border-bottom: 1px solid #dedfe5;
    background-color: #ffffff !important;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .logo-image-of-the-project {
    width: 80px;
    height: 40px;
  }
  .userprofile-image {
    margin: 0px 15px;
    height: 35px;
    width: 35px;
    border-radius: 50% !important;
  }
}