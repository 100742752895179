* {
    padding: 0;
    margin: 0;
}

.all-content-bg {
    background: #f4f5f9;
    height: 100vh;
    padding-top: 10px !important;
}

.padding-set {
    padding: 0px !important;
}