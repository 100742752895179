.AllAi-paths-button button {
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
  background-color: rgba(218, 41, 28, 0.12);
  color: #da291c;
  border: none;
  width: 188px !important;
  height: 47px;
  font-family: Inter;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-container-ofAll-ai-Paths {
  background-color: white;
  border: 0.3px solid #b9b9b9 !important;
  border-radius: 14px;
  /* height: 60vh !important; */
  height: 80vh !important;
}

.height-none {
  height: 0px !important;
}

.submain-container {
  padding: 20px;
  overflow-y: scroll;
  height: 73vh;
  margin: 2px 2px;
}

.Allaipaths2nd {
  padding: 20px;
}

.outer-container-of-ai-paths {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AllAi-paths-heading h3 {
  text-transform: capitalize;
  font-family: Inter;
  font-weight: 700;
  font-size: 14px;
}

.AllAi-paths-heading {
  padding: 15px 0px 20px 0px;
}

.AllAi-paths-list {
  position: relative;
  z-index: 1;
  gap: 10px;
  /* height: 70px; */
  row-gap: 80px;
}

.divider-for-seperator-section {
  margin: 40px 0px !important;
  border: none;
  height: 1px;
  color: #333;  
  background-color: #333;
}

.AllAI-paths {
  display: grid;
  grid-template-columns: repeat(16, auto);
}

.AllAI-paths-list-data-button {
  background: #f5f6fa;
  border: 1px solid red;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 10px;
  height: 45px;
  border-radius: 4px;
  font-weight: 600;
}

.AllAI-paths-list-data-button svg {
  color: #da291c;
  width: 8px;
  height: 8px;
  margin: 4px;
}

.AllAI-paths-list-data-button-new svg {
  color: #da291c;
  width: 8px;
  height: 8px;
  margin: 4px;
}

.AllAI-paths-list-data-button-new {
  cursor: pointer !important;
  background: #f5f6fa;
  border: 1px solid #dedfe5;
  color: black;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: auto;
  height: 45px;
  border-radius: 4px;
  font-weight: 600;
  padding: 10px;
}

.AllAi-Paths-background-line {
  position: absolute;
  top: 20px;
  width: 64%;
  border: 1px solid #dedfe5;
}

.single-line-all-aipath {
  position: absolute;
  top: 20px;
  width: 480px;
  border: 1px solid #dedfe5;
}

.siderbar-modal-img {
  background-color: #ffffff;
  width: 29px;
}

.all-ai-paths-lits-images {
  position: relative;
  z-index: 999;
  justify-content: center;
  display: flex;
  align-items: center;
}

.all-ai-paths-lits-images {
  position: relative;
  z-index: 999;
  justify-content: center;
  display: flex;
  align-items: center;
}

.AllAi-paths-username-container {
  position: relative;
  z-index: 999;
}

.red-doticon-image {
  margin-right: 8px;
  height: 8px;
  width: 8px;
}

.horizontal-line-in-aipath {
  top: 20px;
  display: flex;
  position: relative;
  
}

.delete-button-down {
  display: flex;
  flex-direction: column;
  display: flex;
}

.bottom-line-of-nodes {
  height: 63px;
  background: #c2c2c2;
  width: 0.8px;
  margin-left: 48px;
}

.top-line-of-nodes {
  height: 63px;
  background: #c2c2c2;
  width: 0.8px;
  margin-left: 99%;
}

.main-for-nodes-line {
  width: 95%;
  height: 1px;
  background: #c2c2c2ed;
  margin-left: 48px;
}

.container-of-joint-line-of-node {
  position: absolute;
  top: 23px;
  width: 100%;
}

/* my css */
.remove-username-container2 {
  /* width: 50%; */
  width: fit-content;
}

.innerData_ai_cont {
  width: 25%;
}

.delete-button-down2 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.relation_ai_path_cont {
  width: 80%;
}

.container-of-joint-line-of-node2 {
  position: relative;
  display: block;
  width: 100%;
  top: -37px;
  height: 90px
}

.main-for-nodes-line2 {
  width: 94.1%;
  height: 1px;
  background: #c2c2c2ed;
  margin-left: 6%;
}

.top-line-of-nodes2 {
  height: 75px;
  background: #C2C2C2;
  width: 1px;
  margin-left: 100%;
}

.bottom-line-of-nodes2 {
  height: 55px;
  background: #c2c2c2;
  width: 0.8px;
  margin-left: 6%;
}

.delete-contaoner2 {
  width: 100%;
  display: block;
  position: relative;
}

.weight-between-nodes {
  font-size: 13px;
  background-color: #F5F6FA;
  width: 24px;
  height: 24px;
  text-align: center;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid #DEDFE5;
  margin-right: 6px;
  cursor: pointer;
}

.DeleteButtonVisible {
  display: flex;
  justify-content: center;
  align-items: center;
}

.setthedeletebutton2 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1;
}

/* .sider-var-list-data-button{
  cursor: pointer !important;
  background: #f5f6fa;
  border: 1px solid #dedfe5;
  color: black;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: auto;
  height: 60px;
  border-radius: 4px;
  font-weight: 600;
  padding: 10px;
} */

.AllAi-paths-button{
  display: flex;
  align-items: center;
  gap: 10px ;
}
.jo-former-icon{
  height: 22px;
  width: 22px;
}
.formerjo-p{
  display: flex;
  align-items: center;
  justify-content: center;
}