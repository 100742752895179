.searchicon-in-header {
  height: 20px;
  width: 20px;
  color: #da291c;
}
.search-inner-text{
  text-transform: capitalize !important;
}
.search-inner-text .css-yz9k0d-MuiInputBase-input {
  font-size: 14px;
  font-family: inter;
  font-weight: 500;
  line-height: 16px;
}
.search-inner-text .css-yz9k0d-MuiInputBase-input::placeholder {
  color: #202224 !important;
  opacity: 99999;
}
.full-searchbar .css-1vm0r4s-MuiPaper-root {
  width: 419px !important;
}
.header-main-dashboard-comp .css-1vm0r4s-MuiPaper-root {
  width: 250px !important;
  height: 45px;
}
.full-searchbar-comp {
  border: 1px solid #bcbdc2;
  background-color: #f4f5f9 !important;
  border-radius: 5px;
  padding: 2px 12px !important;
  height: 44px;
  box-shadow: none !important;
}
.full-searchbar {
  border: 1px solid #bcbdc2;
  background-color: #f4f5f9 !important;
  border-radius: 5px;
  padding: 2px 12px !important;
  height: 44px;
  box-shadow: none !important;
}
.Autocompletedropdown {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  background-color: white;
  /* padding: 10px; */
  position: absolute;
  top: 54px;
  width: 420px;
  left: 33px;
  max-height: 48vh;
  min-height: 2vh !important;
  overflow-y: scroll;
}
.NewClassNamepathname {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  background-color: white;
  /* padding: 10px; */
  position: absolute;
  top: 54px;
  width: 420px;
  left: 92px;
  max-height: 48vh;
  min-height: 2vh !important;
  overflow-y: scroll;
}
.outterAutocompletedropdown:hover {
  background-color: #f4f5f9;
}
.outterAutocompletedropdown2{
  background-color: #f4f5f9;
  font-weight: 600;
  color: #DA291C;

}
.useralldata {
  text-transform: capitalize;
  font-size: 13px;
  cursor: pointer;
  padding: 5px 12px;
}
.hidericon {
  display: none;
}
.red-search {
  color: #da291c;
  font-weight: 700;
}
.input-floating-label-adv {
  width: 100%;
  padding: 10px;
  border: 1px solid #DEDFE5;
  border-radius: 4px;
  outline: none;
  height: 40px;
  background-color: #F4F5F9;
  font-size: inherit !important;
}
.css-1hbvpl3-MuiSvgIcon-root {
  width: 20px !important;
  height: 20px !important;
}
.css-1hhw7if-MuiSvgIcon-root {
  position: absolute !important;
  top: 0px !important;
  height: 20px !important;
  left: 0px !important;
  width: 20px !important;
}
.css-11zohuh-MuiSvgIcon-root{
  left: -2px !important;
  top: -2px !important;
}
@media screen and (max-width: 767px) and (min-width: 320px) {
  /* .css-1vm0r4s-MuiPaper-root{
    width: 200px  !important;
  } */
}
.showicons-search {
  cursor: pointer;
  color: #707072;
  font-size: 24px !important;
}
.cross-icon-prospect-search {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) and (min-width: 320px) {
  .full-searchbar {
    width: 100% !important;
  }
  .userprofile-image {
    display: none !important;
  }
  .outer-bellicon-in-header-container {
    display: none;
  }
  .vertical-line {
    display: none;
  }
  .Leades-filter-inner-container-header button {
    display: flex;
    justify-content: flex-start;
  }
  .Autocompletedropdown {
    width: 80% !important;
    left: 10px !important;
  }
}
/* Tab responsive  */
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .search-inner-text .css-yz9k0d-MuiInputBase-input {
    font-size: 13px;
    font-family: inter;
    font-weight: 500;
    line-height: 16px;
  }
}
.adv-search-modal {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 32.68px;
}
.sub-head-adv-search-mdl {
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 20.43px;
  text-align: left;
  padding: 5px 0px 10px 0px;
}
.de_pad-adv-search {
  padding: 22px;
  width: 100% !important;
}
.advance-search-modal {
  overflow-y: auto;
  width: 42%;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #DA291C !important;
}
.prospect-label-adv-mdl .css-ahj2mt-MuiTypography-root {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
}
.de_pad-org-adv-search{
  padding: 22px;
  width: 100% !important;
  height: 80vh;
}
.adv-table-head{
  padding: 22px 22px 0 22px;
  border-bottom: 1px solid #d1d1d1;
}
.ai-leads-table-main-adv{
  height: 65vh;
    display: block;
    overflow-y: scroll;
    border-radius: unset !important;
}