* {
    padding: 0;
    margin: 0;
}
.joi-tutorial-img {
    margin-left: 30px;
}
.parent-joiTraining {
    height: 100vh !important;
}
.box-video-modals:focus-visible {
    outline: none !important;
    border: none !important;
}
.modal-joiTraining-videos .box-video-modals {
    width: 70% !important;
    padding: 5px !important;
    position: relative;
}
.cross-icon-section {
    position: absolute;
    right: -14px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: red;
    color: #fff !important;
    top: -15px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.ai-score-icon {
    width: 14.75px !important;
    height: 14.75px !important;
    color: #003057 !important;
}
/* ......................................  */
.cards-inner-jt {
    box-shadow: 0px 6px 10px 4px #00000026;
    border-radius: 5px;
    background-color: #FFFFFF;
    padding: 10px;
    /* min-height: 360px; */
    display: flex;
    flex-direction: column;
}
.description-cards-jt {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 14.89px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    min-height: 70px;
}
.video-lenght-jt {
    display: flex;
    align-items: center;
    color: #00A3E0;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 9px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
.doc-icon-text {
    display: flex;
    gap: 10px;
    padding: 10px 0px;
}
.title-card-jt {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 14.52px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #DA291C;
}
.outer-card-jt {
    width: 33%;
}
.vd-title-jt {
    padding: 10px 0px 0px 0px;
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 14.89px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    flex: 1 1;
}
.main-joi-cards-jt {
    display: flex;
    gap: 30px;
    padding: 15px 30px;
}
.joi-training-vdo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    cursor: pointer;
}
.joi-training-vdo img {
    width: 100%;
}
.doc-icon-text img {
    width: 21px;
    height: 24px;
}
.text-red-jt{
    color: #DA291C;
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 14.89px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;}
.joi_tr_bd{
    border: 2px solid #C8C9C7;
}