.right-siderbar-data {
  width: 972px !important;
}

.siderbar-right-siderbar-lits-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 1rem;
}

.right-siderbar-button button {
  text-transform: capitalize;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
  background-color: #da291c;
  color: white;
  border: none;
  width: 188px !important;
  height: 47px;
  font-family: Inter;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Siderbar--inner-container h3 {
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #101820;
}

.siderbar-modal-img {
  margin-right: 6px;
  cursor: pointer;
}

.Siderbar--inner-container p {
  text-transform: capitalize;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
}

.sidebar-list-data {
  display: grid;
  grid-template-columns: repeat(2, auto);
  z-index: 999;
  gap: 10px;
  row-gap: 80px;
}

.remove-username-container {
  position: relative;
  z-index: 999;
}

.sidebar-list-data-new-path {
  display: grid;
  grid-template-columns: repeat(4, auto);
  z-index: 999;
}

.sidebar-list-data-new {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
  gap: 30px;
}

.right-siderbar-button {
  width: 29%;
  display: flex;
  align-items: center;
  gap: 44px;
}

.sider-var-list-data-button-new {
  background: #f5f6fa;
  border: 1px solid red;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 10px;
  height: 45px;
  border-radius: 4px;
  font-weight: 600;
}

.sider-var-list-data-button-new svg {
  color: #da291c;
  width: 8px;
  height: 8px;
  margin: 4px;
}

.new-class-after-third-index {
  position: absolute;
  top: 69px;
  left: 367px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
}

.setthedeletebutton {
  display: flex;
  justify-content: center;
  width: 100%;
}

.DeleteButtonVisible {
  cursor: pointer;
  font-weight: 600 !important;
  color: #ea0234;
  margin-top: 4px;
  background: #ea02341f;
  opacity: 0.8;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 91px;
  border-radius: 5px;
  padding: 10px;
  gap: 4px;
}

.DeleteButtonHidden {
  position: absolute;
  top: 3rem;
  background-color: rgba(234, 2, 52, 0.12);
  border: none;
  display: flex;
  align-items: center;
  width: 100px;
  border-radius: 6px;
  justify-content: center;
  height: 35px;
}

.sider-var-list-data-button svg {
  color: #da291c;
  width: 8px;
  height: 8px;
  margin: 4px;
}

.siderbarlistbuttons {
  background: #f5f6fa;
  border: 1px solid #dedfe5;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 10px;
  height: 45px;
  border-radius: 4px;
  font-weight: 600;
}

.siderbarlistbuttons svg {
  color: #003057;
  width: 8px;
  height: 8px;
}

.SIderbarr-inner-list-data h3 {
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
}

.SIderbarr-inner-list-data {
  padding-bottom: 2rem;
}

.siderbar-row {
  display: flex;
  flex-wrap: wrap;
  row-gap: 100px;
  z-index: 1;
    position: relative;
}

.Siderbar-lits-images img {
  background-color: #ffffff;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
}

.Siderbar-lits-images {
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 999;
}

.siderbar-image-container {
  padding: 5px 25px;
}

.siderbarr1stlist {
  padding: 15px 40px 30px;
}

.single-line {
  border: 1px solid #dedfe5;
  position: absolute;
  top: 100px;
  width: 50%;
  left: 12px;
  margin-left: 40px;
}

.Siderbar-lits-map {
  left: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 4rem;
}

.Siderbar-lits-map img {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
}

.DeleteButtonVisible-image {
  width: 15px;
  height: 13px;
}

.background-line {
  border: 1px solid #dedfe5;
  position: absolute;
  top: 100px;
  width: 61%;
  left: 12px;
  margin-left: 40px;
}

.lkjhc {
  border: 1px solid #dedfe5;
  position: absolute;
  top: 100px;
  width: 61%;
  left: 12px;
  margin-left: 40px;
}

.index7 {
  border: 1px solid #dedfe5;
  position: absolute;
  top: 241px;
  width: 100%;
  left: 12px;
  margin-left: 40px;
}

.checktheline {
  border: 1px solid #dedfe5;
  position: absolute;
  top: 96px;
  width: 73%;
  left: 12px;
  margin-left: 40px;
}

.newline {
  border: 1px solid #dedfe5;
  position: absolute;
  top: 21px;
  width: 85%;
  left: 12px;
  margin-left: 40px;
}

.arrow-button-forward-sidebar {
  border-radius: 9px !important;
  border: 1px solid #d5d5d5 !important;
  background-color: #fafbfd !important;
  padding: 0px !important;
  min-width: 0px !important;
  color: #da291c !important;
  width: 36px !important;
  height: 36px !important;
}

.sider-var-list-data-button {
  z-index: 999;
  text-transform: capitalize;
  cursor: pointer !important;
  background: #F5F6FA;
  border: 1px solid #DEDFE5;
  color: black;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: auto;
  height: auto;
  border-radius: 4px;
  font-weight: 600;
  padding: 10px;
}

.red-doticon-image {
  margin-right: 8px;
  height: 8px;
  width: 8px;
}

.red-doticon-image-sidebar {
  margin-right: 8px;
  height: 8px;
  width: 8px;
  text-transform: capitalize;
}

.side-arrow-in-inner-table-aileads {
  font-size: 18px !important;
}

.rightsidebar-path-second {
  padding: 15px 40px 30px;
}

.both-paths {
  overflow-y: scroll;
  height: 100vh;
}

.setthedeletebutton-rightsidebar {
  display: flex;
  justify-content: center;
  width: 100%;
}

.top-line-of-nodes2-sidebar {
  height: 75px;
  background: #c2c2c2;
  width: 0.8px;
  margin-left: 96%;
}

.main-for-nodes-line2 {
  width: 84%;
  height: 1px;
  background: #c2c2c2ed;
  margin-left: 12%;
}

.AllAIOuttercontainer-sidebar {
  padding: 10px 25px 40px 25px;
}

.sider-var-list-data-button-sidebar {
  z-index: 999;
  text-transform: capitalize;
  cursor: pointer !important;
  background: #F5F6FA;
  border: 1px solid #DEDFE5;
  color: black;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: auto;
  height: 60px;
  border-radius: 4px;
  font-weight: 600;
  padding: 10px;
}

.identity-name {
  text-transform: capitalize !important;
}

.container-of-joint-line-of-node2-sidebar {
  position: relative;
  display: block;
  width: 100%;
  top: -40px;
  height: 90px
}

.path-heading-sidebar {
  text-transform: capitalize;
}

/* tab responsive  */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
.css-1160xiw-MuiPaper-root-MuiDrawer-paper{
  width: 80% !important;
}
}
.arrow-button-forward-sidebar-disabled{
  border-radius: 9px !important;
  border: 1px solid #d5d5d5 !important;
  background-color: hsl(220deg 4.04% 64.98%) !important;
  padding: 0px !important;
  min-width: 0px !important;
  color: #da291c !important;
  width: 36px !important;
  height: 36px !important;
}
.divider-for-seperator-section-sidebar{
  height: 1px !important;
  color: #333 !important;  
  background-color: #333 !important;
}