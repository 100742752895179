.maintenance-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0px;
    background: rgb(243, 244, 249);
    /* height: 90vh; */
    flex: 1;
    overflow: hidden;
}

.error-outer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.we-back {
    font-family: Inter;
    font-size: 22px;
    font-weight: 700;
    line-height: 26.63px;
    text-align: center;
    padding: 25px 0px 15px 0px;
}

.our-website {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: center;
    color: #83878D;

}

.thankyou-maintenance {
    font-family: Inter;
    font-size: 30px;
    font-weight: 700;
    line-height: 36.31px;
    text-align: center;
    padding: 25px 0px;
}

.page-not-found {
    font-family: Inter;
    font-size: 26px;
    font-weight: 700;
    line-height: 31.47px;
    text-align: center;
}

.link-errorpage {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: center;
    padding: 15px 0px 25px 0px;
    color: #83878D;
}

.error-return-home {
    background: #DA291C;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    text-align: center;
    padding: 10px 30px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.context-error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 40px; */
}

.errorman-img {
    position: absolute;
    bottom: 41px;
    left: 36%;
    z-index: 999;
}

.errorrod-img {
    position: relative;
    left: 220px;
    z-index: 999;
    height: 288px;
}

.error404-img {
    position: absolute;
    bottom: 0px;
    left: 0;
}
.extra_piece{
    z-index: 999;
    position: relative;
     background: rgb(243, 244, 249);
    height: 40px;
    margin-top: -1px;
}
.errorline-img {
   z-index: 999;
   position: relative;
}

.error-images {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0;
}

.error-screen-main {
    display: flex;
    /* justify-content: flex-end; */
    align-items: center;
    background: rgb(243, 244, 249);
    flex: 1 1;
    flex-direction: column;
    /* padding-bottom: 120px; */
}

.mainte-banner {
    background: #fff;
    padding: 10px;
    height: 60px;
}

.mainte-banner img{
    padding-left: 40px;
}