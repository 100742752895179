.organization-search-ai-path {
    width: 50%;
    height: 161px;
    border: 0.3px solid #b9b9b9;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 14px;
}

.inner-boxs-org {
    position: relative;
    width: 78%;
}
.inputs-button-search-org {
    gap: 17px;
    display: flex;
    align-items: flex-end;
}