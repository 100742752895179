.child-section-of-everypage-aipath {
    padding: 35px !important;
    /* height: 91vh !important; */
    display: flex;
    flex-direction: column;
    gap: 10px !important;
    /* text-transform: capitalize; */
}
.apiath-noresult{
    background-color: white;
    border: 0.3px solid #b9b9b9 !important;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
@media screen and (max-width: 767px) and (min-width: 320px) {
.child-section-of-everypage-aipath{
    padding: 15px !important;
}
}