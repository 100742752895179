@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.Leads-Filter-main-container {
  display: flex !important;
  align-items: center;
  border: 0.6px solid #D5D5D5;
  border-radius: 10px;
  width: 55%;
  background-color: #F9F9FB;
  color: #D5D5D5;
  height: 70px;
  font-family: Inter;
}

.DecisionMaker-filter-main-container {
  display: flex;
  align-items: center;
}


.reset-filter-icon {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #606060;
}

/* .AILeads-Filter1st {
  width: 10%;
  border-right: 1px solid #d5d5d5;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;        
} */
/* .Reset-filter-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  color: #ea0234;
  height: 70px;
  width: 23%;
} */
/* .reset-filter-icon {
  font-size: 14px !important;
  font-weight: 500 !important;
} */
/* .Reset-filter-container div {
  display: flex;
  align-items: center;
} */
/* .Reset-filter-container p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  cursor: pointer;
} */
/* .Leads-Filter-1stdrop-down .MuiFormControl-root .MuiInputBase-root fieldset {
  border: none !important;
} */
/* .hidedata {
  display: none !important;
} */
/* .Leads-Filter-1stdrop-down {
  width: 30% !important;
  border-right: 1px solid #d5d5d5;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* .Leads-Filter-1stdrop-down div {
  margin: 0 !important;
} */
/* .AILeads-Filter2nddrop-down div {
  margin: 0 !important;
} */
/* .Leads-Filter-Laststdrop-down div {
  margin: 0 !important;
} */
/* .AILeads-Filter2nddrop-down .MuiFormControl-root .MuiInputBase-root fieldset {
  border: none !important;
} */
/* .Leads-Filter-1stdrop-down em {
  font-weight: 600;
  font-family: Inter;
  font-size: 14px;
  font-style: unset !important;
} */
/* .Leads-Filter-1stdrop-down p {
  font-weight: 400;
  font-family: Inter;
  font-size: 12px;
  opacity: 0.5;
} */
/* .AILeads-Filter2nddrop-down em {
  font-weight: 600;
  font-size: 14px;
  font-family: Inter;
  font-style: unset !important;
} */
/* .AILeads-Filter2nddrop-down p {
  font-weight: 400;
  font-size: 12px;
  font-family: Inter;
  opacity: 0.5;
} */
/* .Leads-Filter-Laststdrop-down p {
  font-weight: 400;
  font-size: 12px;
  font-family: Inter;
  opacity: 0.5;
} */
/* .Leads-Filter-Laststdrop-down em {
  font-size: 14px;
  font-weight: 600;
  font-family: Inter;
  font-style: unset !important;
} */
/*  
.AILeads-Filter2nddrop-down {
  width: 22%;
  border-right: 1px solid #d5d5d5;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.strength-div{
  width: 22%;
  border-left: 0.3px solid #d5d5d5;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.strength-div em {
  font-weight: 600;
  font-size: 14px;
  font-family: Inter;
  font-style: unset !important;
}
.strength-div p {
  font-weight: 400;
  font-size: 12px;
  font-family: Inter;
  opacity: 0.5;
}
.strength-div .MuiFormControl-root .MuiInputBase-root fieldset {
  border: none !important;
}
.Leads-Filter-Laststdrop-down .MuiFormControl-root .MuiInputBase-root fieldset {
  border: none !important;
}
.Leads-Filter-Laststdrop-down {
  width: 23%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AiLeads-filter-icons {
  height: 10px;
}
.AileadsFilter-Apply-button {
  width: 95%;
  margin: 7px 5px !important;
  background-color: #da291c !important;
  font-family: Inter;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root{
}
.reduce-the-margin {
  margin: 2px !important;
}
.AI-leade-checkbox-and-data-list {
  min-width: 180px;
}
.AI-leade-checkbox-and-data-list:hover{
  background-color: rgba(0, 0, 0, 0.04) !important; 
}
.AI-leade-checkbox-and-data-list-outter {
  overflow-y: scroll;
  height: auto;
  margin-right: 4px;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.menuitems {
  padding: 0px !important;
  font-size: 13px !important;
  font-weight: 400;
  font-family: Inter !important;
  line-height: 156px;
}
.AI-Leadsfilter-items {
  font-size: 13px !important;
  font-weight: 400;
  font-family: Inter;
  line-height: 156px;
}
.AIcheckbox {
  width: 10px;
  color: green !important;
  background-color: green !important;
}
.AI-leade-checkbox-and-data-list svg {
  height: 16px;
  width: 16px;
  margin-left: 10px;
}
.AI-Leadsfilter-items {
  padding: 4px 15px;
}
.strength-div div{
  padding: 0px 8px !important;
  margin: 0 !important;
  width: 100%;
}
.AI_DECISION_MAKERfiltersdata{
  text-transform: capitalize;
  margin-top: 1rem;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
.MyAiProfile-data-list-container-fordecisionmaker{
  width: 18%;
  padding-top: 55px;
}
.MyAiProfile-data-list-container-fordecisionmaker .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.DecisionMaker-filter-main-container{
  display: flex;
  align-items: center;
}
.MyAiProfile-data-list-container-fordecisionmaker .MuiFormControl-root .MuiInputBase-root {
  background-color: #26a0d2;
  width: 100%;
  height: 47px;   
  line-height: 16px;
}
.MyAiProfile-data-list-container-fordecisionmaker em{
  font-weight: 600;
  font-size: 14px;
  font-family: Inter;
  font-style: unset !important;
}
.MyAiProfile-data-list-container-fordecisionmaker .MuiFormControl-root .MuiSelect-select {
  width: 100%;
  margin: 0;
  padding: 8px 8px 8px 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.MyAiProfile-data-list-container-fordecisionmaker .MuiFormControl-root {
  width: 94%;
}
.setSelectProfile-ui{
  font-size: 12px !important;
  font-weight: 400px !important;
}
.menuitems-last-filter {
  padding: 0px !important;
  font-size: 13px !important;
  font-weight: 400;
  font-family: Inter !important;
  line-height: 156px;
  border-bottom: 1px solid #D5D5D5 !important;
}
.menuitems-last-filter .MuiListItemText-root  span{
  font-size: 13px !important;
  font-weight: 400 !important;
}
.AI-leade-checkbox-and-data-list-forpropect-screen{
  width: 100%;
  padding: 0;.AILeads-Filter2nddrop-down
  display: block;
}
.MyAiProfile-data-list-container-fordecisionmaker .MuiFormControl-root  .MuiInputBase-root svg{
  color: white;
} */
.prospect-Filter-main-container {
  width: 80%;
  display: flex !important;
  align-items: center;
  border: 0.6px solid #D5D5D5;
  border-radius: 10px;
  color: #D5D5D5;
  height: 70px;
  font-family: Inter;
  background-color: #F9F9FB !important;
}

.outer-main-prospect-filtersand-buttons {
  text-transform: capitalize;
  margin-top: 1rem;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  padding-top: 50px;
  width: 100%;
}

.AILeads-sinority-down div {
  padding: 0px 5px !important;
  margin: 0 !important;
  width: 100%;
}

.AILeads-sinority-down .MuiFormControl-root .MuiInputBase-root fieldset {
  border: none !important;
}

.AILeads-sinority-down em {
  font-weight: 600;
  font-size: 14px;
  font-family: Inter;
  font-style: unset !important;
}

.AILeads-sinority-down p {
  font-weight: 400;
  font-size: 12px;
  font-family: Inter;
  opacity: 0.5;
}

.AILeads-sinority-down {
  width: 25%;
  border-right: 0.3px solid #D5D5D5;
  height: 70px;
  display: flex;
  align-items: center;
}