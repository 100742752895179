.download-icon-path {
    border-radius: 9px !important;
    border: 1px solid #d5d5d5 !important;
    background-color: #fafbfd !important;
    padding: 0px !important;
    min-width: 0px !important;
    color: #da291c !important;
    width: 36px !important;
    height: 36px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.print-img-aipath-table{
    height: 25px;
    width: 25px;
}

.icons-aipath {
    display: flex;
    gap: 12px;
}

.main-aipath-table {
    padding: 20px;
    border: 0.3px solid #B9B9B9;
    border: 0.3px solid #B9B9B9;
    background-color: #FFFFFF;
    border-radius: 10px;
}

.first-cont-ai-table-path {
    padding: 20px;
}

.ai-path-head-icons {
    display: flex;
    justify-content: space-between;
}

.aipath-table-pera {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;
    padding-bottom: 25px;
}

.ai-path-table-name-cloud {
    display: flex !important;
    gap: 14px !important;
}

.path-table-joistrength {
    width: 35px;
    height: 25px;
    padding: 4px 8px 4px 8px;
    gap: 8px;
    border-radius: 26px;
    background: #0EB93E;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: left;
    color: #ffffff;
}

.path-table-connection {
    width: 32px;
    height: 32px;
    color: #ffffff;
    background: #0EB93E;
    display: flex;
    justify-content: center;
    align-items: center;
}

.path-table-details p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;

}

.right-arrow-aipath-table {
    color: red;
    background-color: #FAFBFD;
    border: 1px solid #D5D5D5;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.ai-path-user-name {
    border: 2px solid #EBEDF5;
    color: #003057;
    width: 46px;
    height: 46px;
    background-color: #f5f6fa;
    border-radius: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
}

.ai-path-table-btn {
    display: flex;
    justify-content: flex-end;
    padding: 20px 10px 10px 0px;
    gap: 10px;
}

.save-btn-aipath-table {
    height: 36px !important;
    padding: 8px 12px 9px 12px !important;
    border-radius: 4px !important;
    background-color: #DA291C !important;
    color: #FFFFFF !important;
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    width: 97px !important;
}

.cancel-btn-aipath-tables {
    height: 36px !important;
    padding: 8px 12px 9px 12px !important;
    border-radius: 4px !important;
    background-color: #FFFFFF !important;
    color: #000000 !important;
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    width: 72px !important;
}