.progress-bar-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .outer-object-for-twitter-timeline {
        width: 100%;
    }
}