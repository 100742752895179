@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.crie-cont-2 {
    padding: 15px;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    width: 100%;
}
.crie-cont-x {
    padding: 0 15px 15px 15px;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    width: 100%;
}

.location_filter_leads_crie {
    padding: 5px 10px;
}

.location_filter_leads_crie li {
    text-transform: capitalize;
}

.crie-filter-inner-container-crie {
    display: flex;
    gap: 10px;
    background-color: #000000 !important;
    font-size: 14px !important;
    font-family: Inter !important;
    font-weight: 600 !important;
    text-transform: none !important;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff !important;
}

.crie-filter-inner-container-crie-disabled {
    display: flex;
    gap: 10px;
    background-color: #0000006b !important;
    font-size: 14px !important;
    font-family: Inter !important;
    font-weight: 600 !important;
    text-transform: none !important;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff !important;
    cursor: not-allowed !important;
}

.crie_indirect_process {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;}

.crie_indirect_process p {
    font-size: 16px;
    font-weight: 600;
    color: #DA291C;
}
.crie-filter-inner-container-search{
    display: flex;
    gap: 10px;
    background-color: #DA291C !important;
    font-size: 14px !important;
    font-family: Inter !important;
    font-weight: 600 !important;
    /* width: 110px; */
    /* height: 47px; */
    text-transform: none !important;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff !important;
}
.crie-filter-inner-container-search:hover {
    background-color: #BB291E !important;
}

.crie-filter-inner-container-crie-disabled:hover{
    background-color: #0000006b !important;
}


.crie_input {
    width: 100%;
    padding: 10px;
    border: 1px solid #d3cccc;
    border-radius: 5px;
}

.crie_input:focus-visible {
    outline: none;
}

.crie_input_cont {
    padding: 2px;
}

.crie_path_cont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
}

.first_crie_box {
    background: #F5F6FA;
    border: 1px solid #C8C9C7;
    border-radius: 5px;
    padding: 6px;
    display: flex;
    gap: 6px;
    width: 240px;
}

.color_crie_red {
    display: block;
    float: left;
    height: 100%;
    width: 10px;
}

.color_crie_red span {
    background: #DA291C;
    height: 8px;
    display: block;
    width: 8px;
    border-radius: 50%;
    margin-top: 16px;
}
.crie_info {
    width: 100%;
}
.crie_info div {
    font-size: 13px;
    font-family: Inter;
    font-weight: 700;
}

.crie_info .company-profile-mention {
    width: 100%;
    display: flex;
    font-size: 12px;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    line-height: 20px;
    font-weight: 500;
    word-break: break-all;
}

.color_crie_blue {
    background: #1C5DDA;
    height: 8px;
    display: block;
    width: 8px;
    border-radius: 50%;
    margin-top: 16px;
}

.crie_line {
    width: 200px;
    height: 1px;
    background: #cccccc;
    position: relative;
}

.crie_line_2 {
    width: 100px;
    height: 1px;
    background: #000;
    position: relative;
}

.crie-img {
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: -10px;
    z-index: 1;
}

.crie-img-2 {
    justify-content: end;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: -10px;
    z-index: 1;
}

.crie_direct {
    font-size: 18px;
    font-weight: 700;
}

.weight-between-nodes-2 {
    font-size: 11px;
    background-color: #F5F6FA;
    width: 21px;
    height: 20px;
    text-align: center;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    display: flex;
    border: 1px solid #DEDFE5;
    cursor: pointer;
    color: #DA291C;
    font-weight: 600;
}

.crie-img img {
    background-color: #ffffff;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
}

.siderbar-modal-img-crie {
    margin-right: 4px;
    cursor: pointer;
    width: 20px !important;
    height: 21px !important;
}

.weight-between-nodes-crie {
    font-size: 11px;
    background-color: #F5F6FA;
    width: 21px;
    height: 20px;
    text-align: center;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    display: flex;
    border: 1px solid #DEDFE5;
    margin-right: 6px;
    cursor: pointer;
    font-weight: 600;
}

.crie_outer_drop {
    position: relative;
}

.crie-drop-down {
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #DA291C;
    padding: 6px;
    font-size: 13px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    min-height: 38px;
}

.crie-filter-inner-container-drop {
    position: absolute;
    background: #fff;
    top: 40px;
    left: 0;
    border: 1px solid #e5dfdf;
    border-radius: 5px;
    color: #000;
    font-size: 13px;
    z-index: 9;
    min-height: auto;
    height: auto;
    max-height: 300px;
    overflow-y: auto;
}

.inner-rest-button-crie {
    text-transform: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #EEDADE;
    padding: 8px;
    font-size: 14px;
    color: #DA291C;
    border-radius: 5px;
    cursor: pointer;
    min-height: 38px;
    min-width: 70px;
}

.inner-rest-button-crie svg {
    color: #DA291C;
}

.crie-inner-drop-boxes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.crie-upload-print-download {
    display: flex;
    gap: 10px;
    width: auto;
    height: auto;
}

.crie-upload-print-download p {
    background-color: #F9FAFD;
    width: 39px;
    height: 39px;
    border-radius: 5px;
    border: 1px solid #C8C9C7;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.crie-cont {
    display: flex;
    justify-content: space-between;
}

.company-head-crie {
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    text-align: left;
}

.urilize-subhead-crie {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
}

.directpath-crie {
    font-family: Inter;
    padding: 20px 0px 5px 0px;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
}

.crie-cont-main-crie {
    padding: 15px 15px 0px 15px;
}

.hyphen-conn {
    position: relative;
    width: 14px;
    height: 14px;
    border-radius: 5px;
    border: 1px;
    background-color: #EEEDED;
    border: 1px solid #C8C9C7;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
}

.line-hyphen-conn {
    width: 50px;
    height: 1px;
    background: #000;
    position: absolute;
    left: -15px;
    top: 7px;
}

.hyphen-conn-main {
    position: relative;
}

.direct-path-crie {
    display: flex;
    align-items: center;
    gap: 5px;
}

.direct-path-crie .Mui-checked {
    color: #DA291C !important;
}

.in-direct-path-crie {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: 20px !important;
}

.path-graph-crie {
    border: 1px solid #d3cccc;
    border-radius: 10px;
    background: #FAFAFA;
}

.radio-input-crie {
    display: flex;
    border-bottom: 1px solid #d3cccc;
    padding: 20px 30px;
    gap: 25px;
}

.direct-path-crie .css-ahj2mt-MuiTypography-root {
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 16.94px !important;
    text-align: left !important;
}

.in-direct-path-crie .css-ahj2mt-MuiTypography-root {
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 16.94px !important;
    text-align: left !important;
    color: #C8C9C7 !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
    padding: 0px !important;
}

.crie_name {
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
}

.company-profile-mention-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 12px;
    gap: 7px;
    line-height: 20px;
    font-weight: 500;
}

.company-profile-mention-2 span {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
    gap: 5px;
}



.crie-a-end {
    justify-content: end !important;
}

.crie_selected_names {
    background: #D9D9D9;
    text-transform: capitalize;
    padding: 8px;
    border-radius: 24px;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
    width: fit-content;
}

.crie_selected_names svg {
    font-size: 20px;
    cursor: pointer;
}

.crie-inner-drop-boxes-2 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: unset;
    padding: 0px 15px;
    min-width: 180px;
}

.cre-list-cont {
    display: flex;
}

.DecisionMaker-table thead tr th {
    color: #000 !important;
    font-weight: 500;
}

.innerData_ai_cont_crie {
    width: 33.3%;
}

.first_crie_box_2 {
    background: #F5F6FA;
    border: 1px solid #C8C9C7;
    border-radius: 5px;
    padding: 6px;
    display: flex;
    gap: 6px;
    width: 65%;
    height: 150px;
}

.relation_ai_path_cont_2 {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.top-line-of-nodes2_crie {
    height: 100px;
    background: #C2C2C2;
    width: 1px;
    margin-left: 100%;
}

.main-for-nodes-line2_crie {
    width: 94.1%;
    height: 1px;
    background: #c2c2c2ed;
    margin-left: 6%;
}

.container-of-joint-line-of-node2-sidebar_crie {
    position: relative;
    display: block;
    width: 100%;
    top: -73px;
    height: 83px;
}

.AllAIOuttercontainer-sidebar_crie {
    padding: 25px 25px 25px 25px;
    border-bottom: 1px solid #e6e6e6;
}

.path_head {
    font-size: 14px;
    font-weight: 800;
    padding: 5px 5px;
}

.AllAIOuttercontainer-sidebar_crie:last-child {
    border: none !important;
}

.crie-load-more {
    margin-bottom: 20px;
}

@media print {
    .do_not_print {
        display: none !important;
    }
}

.useralldata-indirect {
    text-transform: capitalize;
    font-size: 13px;
    cursor: pointer;
    padding: 5px 12px;
    color: #DA291C;
}
.crie-days{
    position: absolute;
    left: 72px;
    /* background: #EC971E; */
    font-size: 11px;
    border-radius: 5px;
    bottom: 13px;
    color: #fff;
    padding: 0 3px;
}
/* .crie-indays{
    position: absolute;
    left: 25px;
    background: #EC971E;
    font-size: 11px;
    border-radius: 5px;
    bottom: 33px;
    color: #fff;
    padding: 0 3px;
} */
.crie-indays { 
    position: absolute;
    left: 25px;
    font-size: 11px;
    border-radius: 5px;
    bottom: 33px;
    color: #fff;
    padding: 0 3px;
}

.relative-crie{
    position: relative;
}