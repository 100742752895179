.ContactUsScreen-container {
  background-color: white;
  width: 100%;
  height: auto;
  border: 0.3px solid #B9B9B9;
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 14px;
}

.ContactUsScreen-main-container {
  height: 100%;
}

.ContactUsScreen-flex-container {
  display: flex;
  gap: 20px;
  width: 100%;
  padding-bottom: 10px;
}

.ContactUsScreen-container-outter {
  padding-top: 18px;
  height: 100%;
}

.box-contactus-modal:focus-visible {
  outline: none !important;
  border: none !important;
}

.modal-img-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.modal-img-section img {
  height: 224px;
  width: 220px;
  margin-bottom: 20px;
}

.content-modal-contactus {
  font-size: 36px !important;
  font-weight: bold !important;
  margin-bottom: 35px !important;
}

.button-modal-contactUs {
  width: 202px;
  height: 50px;
  border: 1px solid #DA291C;
  border-radius: 9px;
  color: #DA291C;
  background-color: #fff;
  font-size: 16px;
  cursor: pointer;
}

/* .lkihygfds{
  background-color: #da291c !important;
} */
.ContactUsScreen-dropdown {
  width: 100%;
}

.ContactUsScreen-child-container .MuiFormControl-root {
  margin: 0;
  width: 100%
}


.ContactUsScreen-child-container .MuiFormControl-root .MuiInputBase-root .MuiSelect-select {
  padding: 8px !important;
  font-size: inherit !important;
  border: 1px solid #DEDFE5 !important;
}

.ContactUsScreen-child-container {
  width: 50%;
}

.ContactUsScreen-lables {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
}

.muifile-uploder {
  width: 100%;
}

.contactusTextarea {
  width: 100%;
  padding-bottom: 40px;
}

.contactusTextarea Textarea {
  box-shadow: none !important;
  width: 100%;
}

.contactusTextarea textarea:focus {
  border-color: #000000;
}

.contactusTextarea textarea:hover {
  border-color: #000000;
}

.muifile-uploder .MuiInputBase-root input {
  padding: 8px;
}

.ContactUs-SUBMIT-button {
  background: #da291c !important;
  color: white;
  height: 39px !important;
  width: 188px !important;
}

.ContactUsScreen-heading p {
  font-weight: 500 !important;
  line-height: 19px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  color: #101820 !important;
  font-size: 16px !important;
  opacity: 0.5 !important;
}

.ContactUsScreen-heading h3 {
  font-size: 20px;
  color: #101820;
}

.SUBMITbutton-div {
  width: 100%;
}

.SUBMITbutton-div .MuiStack-root button {
  width: 15% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContactUs-SUBMIT-button {
  width: 20%;
}

.file-uploader {
  position: relative;
  height: 40px;
}

.file-uploader i {
  position: absolute;
  font-size: 40px;
  left: 20px;
  top: 16px;
  color: white;
  transition: all .2s ease-in-out;
}

.file-uploader input {
  width: 100%;
}

.custom-file-upload {
  /* padding: 16px 0 20px 80px; */
  display: block;
  /* width: 300px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #DEDFE5;
  cursor: pointer;
  position: relative;
  height: 40px;
  color: white;
  font-family: 'Open Sans', sans-serif;
  transition: .2s all ease-in-out;
  border-radius: 4px;
  z-index: 1;
}

.BrowseFilesdiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
}

.BrowseFilesdiv button {
  height: 40px;
  background-color: #FBE8E6;
  color: #E56860;
  border: none;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SUBMITbutton-div svg {
  transform: rotate(270deg);
  /* padding-right: 10px; */
  font-size: 20px;
}

.SUBMITbutton-div button {
  gap: 25px;
}

input[type=file] {
  cursor: pointer;
  position: absolute;
  font-size: 16px;
  text-indent: -100px;
  left: 1px;
  color: rgba(255, 255, 255, .5);
  z-index: 3;
  height: 40px;
  top: 0;
  font-family: 'Open Sans', sans-serif;
}

input[type=file]:focus,
input[type=file]:active {
  outline: transparent;
}

input[type=file]:focus~label i,
input[type=file]:hover~label i {
  transform: scale(1.05);
}

@media screen and (min--moz-device-pixel-ratio:0) {
  input[type=file] {
    left: 80px;
    padding-top: 30px;
  }
}

@supports (-ms-ime-align:auto) {
  input[type=file] {
    padding-top: 0;
    top: 40px;
    height: auto;
    /* width: 250px; */
    left: 80px;
    text-indent: inherit;
  }
}

.uploderinput {
  width: 50%;
}

.chooseFileOpenUpload {
  height: 40px;
  background-color: #FBE8E6;
  color: #DE3E33;
  border: none;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Choose-file-btn {
  border: none;
  background: none !important;
  width: 70%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  font-size: inherit;
}

.Choose-file-btn span {
  font-size: 12px;
  color: black;
}

.mandatoryfields {
  color: red;
  font-size: 14px;
}

.ContactUsScreen-lables-text-field-area:focus-within {
  /* border-color: #000000 !important; */
  --Textarea-focusedHighlight: none !important;
  /* height: 70px !important; */
}

.ContactUsScreen-lables-text-field-area {
  height: 70px !important;
  background-color: #ffffff !important;
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  .ContactUsScreen-flex-container {
    display: grid;
    gap: 0px;
    width: 100%;
    padding-bottom: 0px;
  }

  .ContactUsScreen-child-container {
    padding-bottom: 10px;
    width: 100%;
  }

  .uploderinput {
    padding-bottom: 10px;
    width: 100%;
  }

  .contactusTextarea {
    font-size: 14px;
    width: 100%;
    padding-bottom: 20px;
  }

  .Choose-file-btn {
    border: none;
    background: none !important;
    width: 70%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    font-size: 10px;
  }

  .chooseFileOpenUpload {
    height: 40px;
    background-color: #FBE8E6;
    color: #DE3E33;
    border: none;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
  }

  .chooseFileOpenUpload svg {
    font-size: 14px !important;
  }

  .ContactUsScreen-container {
    height: 70vh;
    overflow-y: scroll;
    padding: 12px !important;
  }

  .SUBMITbutton-div .MuiStack-root button {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
  }

  .SUBMITbutton-div button {
    gap: 10px;
  }

  .ContactUsScreen-heading h3 {
    font-size: 16px;
    color: #101820;
  }

  .ContactUsScreen-heading p {
    font-weight: 500 !important;
    line-height: 19px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    color: #101820 !important;
    font-size: 12px !important;
    opacity: 0.5 !important;
  }

  .ContactUsScreen-lables {
    font-family: Inter;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  .ContactUsScreen-parent {
    padding: 20px !important;
    height: 89vh;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .SUBMITbutton-div .MuiStack-root button {
    width: 30% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .css-1vm0r4s-MuiPaper-root {
    width: 197px !important;
  }

  .chooseFileOpenUpload {
    height: 40px;
    background-color: #FBE8E6;
    color: #DE3E33;
    border: none;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 7px;
  }

  .chooseFileOpenUpload svg {
    font-size: 14px;
  }

  .Choose-file-btn {
    font-size: 10px;
  }

  .contactusTextarea {
    width: 48%;
    padding-bottom: 30px;
  }

  .Choose-file-btn span {
    font-size: 10px;
    color: black;
  }
}