@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
.CompanyProfile-flex-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.CompanyProfile-flex-outter-container {
    width: 55%;
    display: flex;
    gap: 15px;
    text-transform: capitalize;
}
.CompanyProfile-content {
    display: flex;
    gap: 8px;
    padding: 4.6px 0;
}
.CompanyProfile-cont-rightside {
    /* padding-top: 1.5rem; */
    width: 45%;
}
.Netflix-heading {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    font-family: Inter;
    padding-bottom: 4px;
}
/* .CompanyProfile-infomation-main {
    padding: 1.5rem 0 0;
} */
.clickable-link-for-website {
    text-decoration: none !important;
    color: #003057;
}
.CompanyProfile-content p {
    font-family: Inter;
    font-weight: 400;
    line-height: 16.96px;
    font-size: 14px;
    width: 90%;
}
.CompanyProfile-icons {
    width: 14.75px;
    height: 14.75px;
    color: #003057;
}
.CompanyProfile-bio-heading p {
    font-family: Inter;
    font-size: 14px;
    color: #000000;
    opacity: 50%;
    font-weight: 500;
    line-height: 16.5px;
}
.CompanyProfile-bio-heading {
    padding-bottom: 10px;
}
.CompanyProfile-infomation p {
    font-family: Inter;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
}
.CompanyProfile-img {
    width: 97px;
    height: 97px;
    text-transform: uppercase;
    border-radius: 8px;
    border: 2px solid #ebedf5;
    background-color: #f5f6fa;
     display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #003057;
    font-weight: 500;
    align-items: center;
}
.CompanyProfile-img p{
    font-size: 40px;
}
.industry-mentions::first-letter{
    text-transform: capitalize;
}
.test{
    visibility: hidden !important;
}