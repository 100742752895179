@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.defaultExpandedAccordion {
    margin: 0 !important;
    padding: 8px 0;
}

.AccordionSummary-innerheading-and-icon svg {
    background: #E0E0E0;
    color: #7C7C7C;
    border-radius: 30px;
    font-size: 25px;
    padding: 2px;
}

.Faqscreen-main-heading h3 {
    font-family: Inter;
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
}

.Faqscreen-sub-heading h3 {
    font-family: Inter;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
}

.AccordionSummary-innerheading-and-icon h4 {
    font-size: 16px;
    font-family: Inter;
    font-weight: Medium;
}

.defaultAccordionline {
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
}

.defaultAccordionRed-Line {
    height: -webkit-fill-available;
    height: fill;
    position: absolute;
    width: 4px;
    background-color: #DA291C;
}

.defaultAccordionRed-seclast {
    height: -webkit-fill-available;
    height: fill;
    position: absolute;
    width: 4px;
    background-color: #DA291C;
}

.defaultAccordionRed-Line-last {
    height: -webkit-fill-available;
    height: fill;
    position: absolute;
    width: 4px;
    background-color: #DA291C;
}

.AccordionDetailsalldata {
    padding: 20px;
}

.defaultExpandedAccordion .Mui-expanded svg {
    background-color: #DA291C !important;
    color: white !important;
}

.AccordionDetailsalldata h5 {
    font-family: Inter;
    font-size: 16px;
    font-weight: Medium;
    line-height: auto;
}

.AccordionDetailsalldata p {
    font-family: Inter;
    line-height: auto;
    font-size: 14px;
    font-weight: normal;
    padding: 15px 0;
    color: black;
}

.AccordionDetailsalldata td {
    font-family: Inter;
    line-height: auto;
    font-size: 14px;
    font-weight: normal;
    padding: 15px;
    color: black;
}

.AccordionRedDot {
    background-color: #DA291C;
    border-radius: 20px;
    height: 10px;
    width: 10px;
}

.AccordionSummary-innerheading-and-icon .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    gap: 13px;
}

.defaultExpandedAccordion::before {
    margin: 0 15px !important;
    opacity: unset !important;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

/* td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
} */
.text-glossary {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    /* background-color: #dddddd; */
}

.faqimg {
    width: 100%;
    height: 630px;
    padding: 0 90px;

}

.ol-faq {
    list-style-position: inside;

    font-family: Inter;
    line-height: auto;
    font-size: 14px;
    font-weight: normal;
    padding: 15px 0;
    color: black;

}

.ol-innner {
    padding: 0 0 0 50px;
}

/* Mobile Responsive  */
@media screen and (max-width: 767px) and (min-width: 320px) {
    .defaultAccordionRed-Line-last {
        height: 48%;
        position: absolute;
        width: 4px;
        background-color: #DA291C;
    }

    .AccordionDetailsalldata p {
        padding: 5px 0;
    }

    .AccordionSummary-innerheading-and-icon h4 {
        width: 90%;
    }
}