.leades-filter-inner-container-button {
  background-color: #bb291e;
  font-size: 11px;
  font-family: Inter;
  font-weight: 600;
  width: 141px !important;
  height: 47px;
  display: flex;
  justify-content: space-between;
  padding: 10px, 17px, 10px, 17px;
}
.set-tabs-ai-section {
  padding: 26px !important;
}
.save-search-button p {
  opacity: unset !important;
  color: #ffffff !important;
}
.save-search-button {
  border-radius: 4px;
  border: none;
  width: 141px;
  height: 47px;
  padding: 10px, 17px, 10px, 17px;
  background-color: #af1e14;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.new-prospect-heading{
text-transform: capitalize;
}