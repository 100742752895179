.ComingSoon-outerr-container {
  background-color: white;
}
.ComingSoon-main-container {
  background-color: rgb(243, 244, 249) !important;
  text-transform: capitalize;
}
.BacktoDashbaordbutton {
  border: 1px solid #da291c !important;
  color: #da291c !important;
  font-size: 16px !important;
  font-weight: normal !important;
}
.ComingSoon-inner h3 {
  font-size: 46px;
  font-weight: bold;
}
.ComingSoon-inner {
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ComingSoonleftside {
  text-align: center;
  font-size: 16px;
  padding-top: 6px;
  font-weight: 400;
}
.BacktoDashbaordbuttonstack {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}
.ComingSoon-inner-heading h3 {
  text-align: center;
}
.ComingSoon-inner-heading p {
  color: #bbbbbb;
}
.ComingSoon-scheduled {
  font-size: 19px;
  font-weight: bold;
}
/* Mobile Responsive  */
@media screen and (max-width: 767px) and (min-width: 320px) {
  .ComingSoon-inner {
    padding: 15px;
    width: 100%;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ComingSoon-outerr-container {
    width: 100%;
  }
  .ComingSoon-inner-heading {
    width: 100%;
  }
  .ComingSoon-inner h3 {
    font-size: 42px;
    font-weight: bold;
  }
}
/* tab responsive  */
@media screen and (max-width: 1024px) and (min-width: 768px) {}
.jb-newsfd-head {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
}
.jb-newsfd-head-at {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
  color: #878b87;
}
.main-jb-nf {
  display: flex;
  flex-direction: column;
}
.jb-title-date {
  display: flex;
  justify-content: space-between;
}
.jb-date-time {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
}
.inner-cnt-jb-nf {
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  padding: 20px 0px 15px 0px;
}
.Intersect-jb-nf p {
  background: #F0F0F0;
  border: 0.55px solid #7DCB93;
  border-radius: 16px;
  padding: 2px 15px;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}
.Intersect-jb-nf {
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
}
.millon-jb-nf p {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
}
.millon-jb-nf {
  display: flex;
  gap: 10px;
  padding-top: 15px;
}
.jb-news-main {
  border-bottom: 1px solid #DEDFE5;
  width: 100%;
}
.name-letter-news-feed-jb p {
  background-color: #323232;
  height: 50px;
  width: 50px;
  border-radius: 30px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  font-weight: 400;
}
.name-tilts-date-neewsfeed-jb {
  display: flex;
  padding: 25px;
  gap: 20px;
}
.text-center-result{
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  padding: 36px;
}
