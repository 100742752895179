* {
  padding: 0;
  margin: 0;
}
.loader-main-div {
  width: 100%;
  display: block;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
}

.loader-second-div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-second-div span{
  width: 70px !important;  
  height: 70px !important;
  color: grey;
}
.loader-spinner {
  z-index: 99999;
  width: 60px;
  height: 60px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}