.list-text-item {
  display: none !important;
}
.buttons-of-the-list-item-last{
  padding: 12px 20px !important;
}
.buttons-of-the-list-item {
  padding: 12px 20px !important;
  border-bottom: 0.5px solid #dedfe5 !important;
}
.email-id-and-paste {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  /* gap: 35px !important; */
  width: 100% !important;
}
.list-button-item-start {
  padding: 0px !important;
  justify-content: center !important;
  display: flex !important ;
}
.dropdownbox-main-container {
  background-color: #ffffff !important;
  box-shadow: 0px 13px 61px 0px #a9a9a95d !important;
  border-radius: 5px !important;
  position: absolute;
  z-index: 1;
  top: 10px;
  width: 100%;
  right: 5px;
}
.lkjhgfdxfgy{
  position: absolute;
  width: 100%;
}
.icons-in-dropdown {
  margin-right: 8px !important;
}
.select-icon-arrow {
  color: #003057 !important;
  width: 15px !important;
  height: 15px !important;
  border: 2px !important;
}
.inner-icons-and-mail-ids{
    display: flex;
}
.arrow-down-of-suspect-select{
    margin-right: 25px !important;
}
.ContactDetailList-dropdown{
  width: 230px !important;
  text-transform: lowercase;
}
.setlinkedinui{
  text-decoration: none;
  color: black;
  display: flex;
}
