.DecisionMaker-table-main {
  border: 0.6px solid #D5D5D5;
  border-radius: 14px !important;
  box-shadow: none !important;
  font-family: Inter;
}

.DecisionMaker-table thead tr {
  background-color: #fcfdfd;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.DecisionMaker-table .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
  border-bottom: none !important;
  text-align: left;
}

.DecisionMakecheckbox-row-table {
  width: 5% !important;
}

.Decisions-row-tableNameaAndtitle,
.industry-row-tableStatus,
.prospects-row-tableContact {
  width: 17%;
  text-align: center !important;
}

.DecisionmakerScore {
  background-color: #0eb93e;
  color: white;
  padding: 4px, 8px, 4px, 8px;
  border-radius: 26px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
}

.Decision-maker-userTeblesell {
  min-width: 12rem;
  /* position: relative; */
}

.Decision-maker-user-name-main-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.Decision-maker-user-name {
  border: 2px solid #da291c;
  color: #003057;
  width: 46px;
  height: 46px;
  background-color: #f5f6fa;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}

.decisionMakerImage {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  left: 65px;
}

.button-collapse-table svg {
  font-size: 1rem;
  font-weight: 700;
}

.DecisionMaker-table-main tr {
  border-bottom: 0.6px solid #dedfe5;
}

.DecisionMaker-table .MuiTableBody-root .MuiTableRow-root:last-child {
  border: none !important;
}

.DecisionMaker-table-added .MuiTableBody-root .MuiTableRow-root:last-child {
  border: none !important;
}

.Suspect-table-data {
  text-transform: capitalize;
  display: flex;
  justify-content: center;
}

.Suspect-table-data svg {
  color: #da291c;
}

.DecisionMakercheckbox-row-table input {
  height: 16px;
  width: 16px;
  border: 1px solid red;
}

.score-pera-tag {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
}

.DecisionMaker-table .MuiTable-root .MuiTableBody-root .MuiTableRow-root:last-child {
  border-bottom: unset;
}

.Decision-maker-user-namenoborder {
  color: #003057;
  position: relative;
  width: 46px;
  height: 46px;
  background-color: #F5F6FA;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}

.hide-image {
  display: none;
}

.Decisionstable {
  width: 12%;
}

.name-and-title-text {
  width: 100%;
}

.letter-heading {
  text-transform: uppercase !important;
}

.strengthdata {
  text-align: center;
}

.title-of-company-profile-screen {
  text-align: left;
  /* padding-left: 20px; */
}

.industry-sec-ai-to-compony-profile {
  text-align: center;
  position: unset !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 13%;

  /* width: 33%; */
}

.contact-details-leads-to-company-profile {
  text-align: center;
  position: unset !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 20%;
}

.Decisions-row-tableNameaAndtitle-leads-to-companyprofile {
  width: 22%;
}

.DecisionstableJOIStrength {
  width: 12%;
}

.employee-row-tableCompanydata {
  width: 15%;
}

.company-tag-leads-to-company {
  justify-content: center;
  display: flex;
}

.Contact-Details-heading-leads-to-profile {
  display: flex;
  justify-content: right;
}

.DecisionMaker-table .Decisions-row-empty {
  padding: 0px 0px 0px 15px !important;
  position: relative;
}

.after-company-name-country-prospect-new {
  text-align: left;
  text-transform: capitalize;
}

.decisionMakerImage-new {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  top: -7px;
  left: 27px;
}

.company-name-country-prospect-added {
  text-align: left;
  display: flex;
  justify-content: left;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  color: #202224;
  line-height: 24px;
}

.email-and-other-info-for-empolyee-table {
  position: absolute !important;
  display: flex;
  gap: 32px;
  top: 20px;
}

.sector-heading-companyscreen {
  display: flex;
}

.sector-heading-companyscreen {
  display: flex;
  justify-content: center;
  /* padding-left: 11px; */
}

.annual-row-tableIndustrySector {
  width: 20%;
}

.table-cellhandleRightsidebar-edit {
  display: flex;
  justify-content: flex-end;
}

.table-cellhandleRightsidebar-newclasss {
  width: 7% !important;
}

.button-collapse-table-for-company {
  border: 1px solid #d5d5d5 !important;
  border-radius: 9px !important;
  background-color: #FAFBFD !important;
  width: 36px;
  height: 36px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 !important;
}

.button-collapse-table-for-company svg {
  font-size: 1rem;
  font-weight: 700;
}

.DecisionMaker-table-added .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
  border-bottom: none !important;
  text-align: left;
  padding: 17px;
  line-height: 13px !important;

}

.crieboxes-emptable {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr) !important;
}

.Com-details-prospect-criecards img {
  justify-content: center;
}

.Com-details-prospect-criecards {
  text-align: center;
  display: flex;
  cursor: pointer;
}

.crie_name-cards-names {
  font-size: 13px;
  font-family: Inter;
  font-weight: 700;
}

.crie_name-cards {
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
}

.first_crie_box-cards {
  background: #F5F6FA;
  border: 1px solid #C8C9C7;
  border-radius: 5px;
  padding: 6px;
  display: flex;
  gap: 6px;
  width: 224px;
}

.crie_info-cards {
  width: 100%;
}

.crie_info-cards div {
  font-size: 13px;
  font-family: Inter;
  /* font-weight: 700; */
}

.crie_info-cards .company-profile-mention {
  width: 100%;
  display: flex;
  font-size: 12px;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
  line-height: 20px;
  font-weight: 500;
  word-break: break-all;
  color: #000000DE !important;
}
.crie_info-cards .company-profile-mention-linkedin {
  width: 100%;
  display: flex;
  font-size: 12px;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
  line-height: 20px;
  font-weight: 500;
  word-break: break-all;
  color: #000000DE !important;
  cursor: pointer;
}
.connections-num {
  background-color: #00A3E0;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  width: 15px;
  height: 15px;
  font-size: 10px;
}

.d-connections-card {
  display: flex;
  align-items: center;
  gap: 7px;
}

.inner-card-criesale {
  display: flex;
  gap: 10px;
  width: 100%;
}

.decisionMakerImage-new-cards {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  position: absolute;
  top: -6px;
  left: 20px;
}

.Decision-maker-user-name-cards {
  border: 2px solid #da291c;
  color: #003057;
  width: 40px;
  height: 34px;
  background-color: #f5f6fa;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  position: relative;
}


.Decision-maker-user-namenoborder-cards {
  color: #003057;
  position: relative;
  width: 40px;
  height: 34px;
  background-color: #F5F6FA;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #C8C9C7
}

.table-row-ai-leads-cards p {
  color: #000 !important;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  padding: 16px;
}

.table-row-ai-leads-cards {
  display: flex;
  padding: 0px 16px;
  justify-content: space-between;
  border: 1px solid #E7E7E7}

.titlescards-emp-table {
  display: flex;
  gap: 30px;
}
.d-connections-card-zero{
  cursor: not-allowed;
  display: flex;
  align-items: center;
  gap: 7px;
}
.company-profile-mention{
  text-decoration: none !important;
}