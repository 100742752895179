.legislative-map-head {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 31.47px;
    text-align: left;
}

.legislative-map-content {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    color: #101820;
    opacity: 0.5;
}

.lg-map-main-container {
    padding: 20px;
}

.download-icon {
    border-radius: 9px !important;
    border: 1px solid #d5d5d5 !important;
    background-color: #fafbfd !important;
    padding: 0px !important;
    min-width: 0px !important;
    color: #da291c !important;
    width: 36px !important;
    height: 36px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.model-head-lmap p {
    font-family: Inter;
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
}

.model-main-lmap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.model-main-lmap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.model-head-lmap {
    display: flex;
    align-items: center;
    gap: 12px;
}

.model-select-lmap {
    width: 182px;
    height: 39px;
    padding: 11.5px;
    border-radius: 5px;
    border: 1px solid #DEDFE5;
}

.inner-model-lmap-content {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.inner-title-lmap {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
}