@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.CompanyProfile-main-container {
    padding-top: 80px
}

.CompanyProfile-flex-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.CompanyProfile-flex-outter-container {
    display: flex;
    gap: 15px;
    text-transform: capitalize;
    width: 55%;
}

.CompanyProfile-content {
    display: flex;
    gap: 8px;
    padding: 4.6px 0;
}

.CompanyProfile-cont-rightside {
    width: 45%;
}

.Netflix-heading {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    font-family: Inter;
    padding-bottom: 4px;
}

/* .CompanyProfile-infomation-main {
    padding: 1.5rem 0 0;
} */
.clickable-link-for-website {
    text-decoration: none !important;
    color: #003057;
}

.CompanyProfile-content p {
    font-family: Inter;
    font-weight: 400;
    line-height: 16.96px;
    font-size: 14px;
    width: 90%;
}

.CompanyProfile-icons {
    width: 14.75px;
    height: 14.75px;
    color: #003057;
}

.CompanyProfile-bio-heading p {
    font-family: Inter;
    font-size: 14px;
    color: #000000;
    opacity: 50%;
    font-weight: 500;
    line-height: 16.5px;
}

.CompanyProfile-bio-heading {
    padding-bottom: 10px;
}

.CompanyProfile-infomation p {
    font-family: Inter;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    text-transform: inherit !important;
}

.company_bio_trio,
.CompanyProfile-infomation {
    text-transform: none !important;
}

.CompanyProfile-img {
    width: 97px;
    height: 97px;
    text-transform: uppercase;
    border-radius: 8px;
    border: 2px solid #ebedf5;
    background-color: #f5f6fa;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #003057;
    font-weight: 500;
    align-items: center;
}

.CompanyProfile-img p {
    font-size: 40px;
}

.industry-mentions::first-letter {
    text-transform: capitalize;
}

.flex-container-forCompanyProfile-action-button {
    display: flex;
    justify-content: flex-end;
}

.seprator-line-forcompany {
    width: 100%;
    background: #DEDFE5;
    height: 1px;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 20px !important;
}

.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin: 0px !important;
}

.downloadpdf-btn-companyprofile {
    color: #ffffff !important;
    background: #DA291C !important;
    min-width: 85px;
    text-transform: capitalize !important;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 8px;
    border-radius: 4px;
    cursor: pointer;
    border: 0;
}

.cancel-btn-companyprofile {
    color: #000 !important;
    background: #ffffff !important;
    min-width: 85px;
    text-transform: capitalize !important;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 8px;
    border-radius: 4px;
    cursor: pointer;
    border: 0;
}

.btns-companyprofile-cncl-sumbmit {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    padding-top: 40px;
}
.video-space-org-details{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }