.child-section-of-everypage-CompanyProfileScreen {
  padding: 30px !important;
  background-color: #ffffff !important;
}
.child-section-CompanyProfileTabs {
  background-color: #ffffff !important;
}
.sdede{
  position: fixed !important;
  left: 1000rem !important;
}
    