* {
    padding: 0;
    margin: 0;
}

.floating-label-input {
    position: relative;
}

.input-floating-label {
    width: 100%;
    padding: 10px;
    border: 1px solid #DEDFE5;
    border-radius: 4px;
    outline: none;
    height: 40px;
    background-color: white;
    font-size: inherit !important;
}
.cursor-not-allowed{
    cursor: not-allowed;
}

.label-floating {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #000000;
}

.focused label {
    top: -1px;
    font-size: 12px;
    color: #007bff;
    padding: 0 5px;
    background: #fff;

}