@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.de_modal_cont {
    height: 100vh;
    overflow: auto;
}

.de_head {
    font-size: 13px;
}

.de_input_cont {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4px 0;
}

.de_input_cont label {
    font-size: 12px;
    color: #000;
    font-weight: 500;
    margin-bottom: 3px;
}

.de_input_cont label span {
    color: red;
}

.de_input_cont input {
    background: #F4F5F9;
    border: 1px solid #DEDFE5;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 12px;
}

.de_input_cont input:focus-visible {
    outline: none;
}


.de_red {
    color: #DA291C;
    font-size: 13px;
}

.de_pad {
    padding: 16px 16px 10px 16px;
}

.de_hr-org {
    padding: 10px 16px 10px 16px;
}

.de_hr {
    padding: 10px 0px 10px 0px;
}

.de_btn_cont {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding-top: 10px;
}

.de_cancel {
    text-transform: capitalize !important;
    color: #000 !important;
    min-width: 85px;
}

.de_back {
    color: #DA291C !important;
    border: 1px solid #000 !important;
    min-width: 85px;
    text-transform: capitalize !important;
}

.de_next {
    color: #ffffff !important;
    background: #DA291C !important;
    min-width: 85px;
    text-transform: capitalize !important;
}

.de_next-disabled {
    color: #ffffff !important;
    background: hsl(220deg 4.04% 64.98%) !important;
    min-width: 85px;
    text-transform: capitalize !important;
}

.de_overflow_cont {
    max-height: 98vh;
    overflow-y: auto;
}

.de_wrapper {
    width: 450px;
    margin: 0;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    text-align: center;
}

.de_fileInput {
    display: none;
}

.de_inactive {
    border: 2px dashed #DA291C;
    padding: 40px;
    cursor: pointer;
    background-color: #FFEDEC;
    border-radius: 8px;
}

.de_active {
    border: 2px dashed #DA291C;
    padding: 40px;
    cursor: pointer;
    background-color: #FFEDEC;
    border-radius: 8px;
}

.de_footer {
    margin-top: 20px;
}

.de_wrapper h3 {
    text-align: left;
    font-size: 18px;
    margin-bottom: 10px;
}

.de_file_name {
    color: #DA291C;
    display: flex;
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 700;
}

.de_drag_content {
    font-size: 13px;
    text-align: start;
    margin: 10px 0px;
}

.inner-cont-box {
    width: 100%;
    height: 150px;
    top: 131px;
    left: 290px;
    gap: 0px;
    border-radius: 11px;
    background-color: #F1F1F1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0;
    left: 0;
}

.outer-cont-box {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 10px;
}

.two-cards-dataenrich {
    padding: 20px;
    display: flex;
    gap: 20px;
}

.org-head-enrich {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 29.05px;
    text-align: center;
    padding-top: 10px;
}

.org-content-enrich {
    font-family: Inter;
    padding: 10px 0px 10px 0px;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #666666;
}

.upload-cv-enrich {
    cursor: pointer;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #DA291C;
    background-color: #ffffff;
    color: #DA291C;
    font-family: Inter;
    font-size: 16x;
    font-weight: 600;
    line-height: 21.78px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.upload-cv-enrich p {
    color: #DA291C;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.78px;
}

.upload-cv-enrich:hover p {
    color: #ffffff;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.78px;
}

.upload-cv-enrich img {
    color: #DA291C;
}

.upload-cv-enrich:hover {
    cursor: pointer;
    width: 100%;
    height: 50px;
    top: 471px;
    left: 295px;
    gap: 0px;
    border-radius: 10px;
    border: 1px 0px 0px 0px;
    background-color: #DA291C;
    color: #ffffff;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.78px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid #DA291C;
}

.upload-cv-enrich img {
    width: 28px;
    height: 21.33px;
}

.download-cv-enrich {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: center;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 12px 0px 12px 0px;
    color: #404040;
    text-decoration: none;
}

.download-cv-enrich img {
    height: 16px;
    width: 16px;
}

.table-enrich {
    padding: 20px;
}

.table-enrich p {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 24.2px;
    text-align: left;
    color: #101820;
}



.enrich-bg-card {
    background-color: #ffffff;
    width: 60%;
    height: 115px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 7px;
}

.round-shape-enrich {
    width: 28.7px;
    height: 28.7px;
    background-color: #D9D9D9;
    border-radius: 30px;
}

.rectangle-shape-enrich {
    width: 78.91px;
    height: 10.04px;
    border-radius: 2px;
    background-color: #D9D9D9;
}

.shapes-enrich {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.square-shape-enrich {
    width: 11.48px;
    height: 10.04px;
    border-radius: 2px;
    background-color: #D9D9D9;
}

.inner-card-table-row {
    width: 86%;
    border: 1px solid #FFC3CE;
    background-color: #FFFFFF;
    height: 38px;
    border-radius: 5px;
    position: absolute;
}

.inner-box-bodytag-enrich {
    display: flex !important;
    justify-content: space-around;
    align-items: center;
    height: 38px;
}

.inner-box-row-no {
    width: 27px;
    height: 18px;
    padding: 4px 8px 4px 8px;
    background-color: #0EB93E;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
}

.inner-box-row-fname {
    width: 26px;
    height: 26px;
    border: 1px solid #EBEDF5;
    background-color: #F5F6FA;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px
}

.tc-cell-enrich {
    padding: 0px !important;
    border: none !important;
}

.add-icon-inner-enrich {
    border: 1px solid #D5D5D5 !important;
    border-radius: 9px !important;
    background-color: #FAFBFD !important;
    width: 26px;
    height: 26px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: #DA291C;
}

.tc-cell-enrich p {
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 12.1px;
}

.add-icon-main-enrich {
    height: 18px;
    width: 18px;
}

.label-form-enrich {
    padding-bottom: 0px !important;
}

.records-heading-enrich {
    color: #DA291C;
    display: flex;
    justify-content: center;
    width: 100%;
}

.gray_row_table td {
    color: #c8c5c5
}

.dt-en-red {
    color: #DA291C;
}

.checkboxes-optns {
    margin-top: 12px;
}

.checkboxes-optns p {
    /* display: flex; */
    display: flow;
    gap: 10px;
    font-size: 13px;
    text-align: start;
    /* margin-top: 7px; */
    font-family: Inter;
}

.disclaimer-defiend {
    display: flex;
    gap: 10px;
    font-size: 13px;
    font-style: italic;
    color: gray;
    font-family: Inter;
}

.checkboxes-optns input {
    cursor: pointer;
}

.person-auth input {
    font-size: 11px;
    padding: 5px 3px;
    width: 46%;
    font-family: Inter;
}

.inputs-plus-box-dataenrich {
    display: flex;
    align-items: center;
    gap: 10px;
}

.person-auth input:focus-visible {
    outline: none !important;
}

.auth-person-lbl {
    font-size: 13px;
    font-family: Inter;
}

.person-auth {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 0px 10px 0px;
}

.enrich-table-content {
    position: relative;
}

.tooltip-enrich-tablelist {
    visibility: hidden;
    background-color: #ffffff;
    color: #000000;
    text-align: center;
    padding: 10px;
    position: absolute;
    top: -8px;
    left: 65%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 4px;
    border: 0.5px solid #D5D5D5;
    box-shadow: 0px 13px 61px 0px #A9A9A95D;
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 18.94px;
    text-align: left;
    padding: 8px 15px;
    font-size: 14px;
}

.enrich-table-content:hover .tooltip-enrich-tablelist {
    visibility: visible;
    opacity: 1;
}

.records-hover-table-enrich {
    display: flex;
    align-items: center;
}

.records-value-hover-enrich {
    font-family: Inter !important;
    font-weight: 700 !important;
    font-size: 13px !important;
    line-height: 15.73px !important;
    letter-spacing: 0px !important;

}

.enrich-table-content p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: center;
    color: #202224;
}

.tooltip-enrich-tablelist-empty {
    padding: 0px !important;
    border: 0px !important;
}

.list-src-select-main .MuiSelect-select {
    padding: 5px !important;
    border: 1px solid #C8C9C7;
    background: #F6F4F4;

}

.list-src-heading {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0%;
    font-style: normal;
}