.record-update-head {
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.68px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.record-heading-titleLeadsprospect {
    padding-bottom: 20px;
}

.de_input_cont-wdt {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 4px 0;
}

.de_input_cont-wdt label {
    font-size: 12px;
    color: #000;
    font-weight: 500;
    margin-bottom: 3px;
}

.de_input_cont-wdt label span {
    color: red;
}

.de_input_cont-wdt input {
    background: #F4F5F9;
    border: 1px solid #DEDFE5;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 12px;
}

.de_input_cont-wdt input:focus-visible {
    outline: none;
}

.country-city-request-model {
    display: flex;
    gap: 30px;
}
.de_pad-requpdate {
    width: 100%;
    padding: 10px 20px 10px 20px;
}