@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.Leads-Filter-main-container {
    display: flex !important;
    align-items: center;
    border: 0.6px solid #D5D5D5;
    border-radius: 10px;
    width: 65%;
    color: #D5D5D5;
    height: 70px;
    font-family: Inter;
}
.AILeads-Filter1st {
    width: 10%;
    border-right: 1px solid #D5D5D5;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Reset-filter-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    color: #EA0234;
    height: 39px;
    border: 1px solid #606060;
    padding: 0px 10px;
    border-radius: 5px;
}
.reset-filter-icon {
    font-size: 14px !important;
    font-weight: 500 !important;
}
.Reset-filter-container div {
    display: flex;
    gap: 5px;
    align-items: center;
}
.Reset-filter-container p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    cursor: pointer;
    color: #606060;
}
.Leads-Filter-1stdrop-down .MuiFormControl-root .MuiInputBase-root fieldset {
    border: none !important;
}
.hidedata {
    display: none !important;
}
.Leads-Filter-1stdrop-down {
    width: 18%;
    border-right: 1px solid #d5d5d5;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Leads-Filter-1stdrop-down div {
    padding: 0px 5px !important;
    margin: 0 !important;
    width: 100%;
}
.AILeads-Filter2nddrop-down div {
    padding: 0px 5px !important;
    margin: 0 !important;
    width: 100%;
}
.Leads-Filter-Laststdrop-down div {
    padding: 0px 5px !important;
    margin: 0 !important;
    width: 100%;
}
.AILeads-Filter2nddrop-down .MuiFormControl-root .MuiInputBase-root fieldset {
    border: none !important;
}
.Leads-Filter-1stdrop-down em {
    font-weight: 600;
    font-family: Inter;
    font-size: 14px;
    font-style: unset !important;
}
.Leads-Filter-1stdrop-down p {
    font-weight: 400;
    font-family: Inter;
    font-size: 12px;
    opacity: 0.5;
}
.AILeads-Filter2nddrop-down em {
    font-weight: 600;
    font-size: 14px;
    font-family: Inter;
    font-style: unset !important;
}
.AILeads-Filter2nddrop-down p {
    font-weight: 400;
    font-size: 12px;
    font-family: Inter;
    opacity: 0.5;
}
.Leads-Filter-Laststdrop-down p {
    font-weight: 400;
    font-size: 12px;
    font-family: Inter;
    opacity: 0.5;
}
.Leads-Filter-Laststdrop-down em {
    font-size: 14px;    
    font-weight: 600;
    font-family: Inter;
    font-style: unset !important;
}
.AILeads-Filter2nddrop-down {
    width: 30%;
    border-right: 0.3px solid #D5D5D5;
    height: 70px;
    display: flex;
    align-items: center;
}
.JScoreContainer {
    width: 30% !important;
    border-left: 0.3px solid #D5D5D5;
    height: 70px;
    display: flex;
    align-items: center;
}
.JScoreContainer .css-1869usk-MuiFormControl-root {
    width: 100% !important;
}
.JScoreContainer .MuiFormControl-root .MuiInputBase-root fieldset {
    border: none !important;
}
.JScoreContainer em {
    font-weight: 600;
    font-size: 14px;
    font-family: Inter;
    font-style: unset !important;
}
.JScoreContainer p {
    font-weight: 400;
    font-size: 12px;
    font-family: Inter;
    opacity: 0.5;
}
.Leads-Filter-Laststdrop-down .MuiFormControl-root .MuiInputBase-root fieldset {
    border: none !important;
}
.Leads-Filter-Laststdrop-down {
    width: 30%;
    height: 70px;
    display: flex;
    align-items: center;
}
.AiLeads-filter-icons {
    height: 10px;
}
.reduce-the-margin {
    margin: 2px !important;
}
.AI-leade-checkbox-and-data-list {
    /* border-bottom: 1px solid #D5D5D5; */
    display: flex;
    padding: 0 3px;
    min-width: 180px;
}
.AI-leade-checkbox-and-data-list_2 {
    /* border-bottom: 1px solid #D5D5D5; */
    display: flex;
    padding: 0 3px;
    min-width: 180px;
}
.AI-leade-checkbox-and-data-list:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
}
.AI-leade-checkbox-and-data-list_2:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
}
/* .AI-leade-checkbox-and-data-list-last-filter {
    /* border-bottom: 1px solid #D5D5D5 !important; */
    /* padding: 0 3px; */
    /* min-width: 180px; 
} */
.AI-leade-checkbox-and-data-list-last-filter:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
}
.AI-leade-checkbox-and-data-list-outter {
    overflow-y: auto;
    min-height: 5vh;
    max-height: 50vh !important;
    /* margin-right: 4px; */
}
.MyAiProfile-data-list-container .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
    border: none !important;
    position: relative !important;
}
.AI-leade-checkbox-and-data-list-outter::-webkit-scrollbar {
    width: 8px;
}
.AI-leade-checkbox-and-data-list-outter::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}
.AI-leade-checkbox-and-data-list-outter::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}
.menuitems {
    padding: 0px !important;
    font-size: 13px !important;
    font-weight: 400;
    font-family: Inter !important;
    color: #000 !important;
    /* line-height: 156px; */
    /* border-bottom: 1px solid black !important; */
}
.menuitems-last-filter {
    padding: 0px !important;
    font-size: 13px !important;
    font-weight: 400;
    font-family: Inter !important;
    line-height: 156px;
    border-bottom: 1px solid #D5D5D5 !important;
}
.menuitems-last-filter .MuiListItemText-root span {
    padding: 5px 10px;
    font-size: 13px !important;
    font-weight: 400 !important;
}
.AI-Leadsfilter-items {
    font-size: 14px !important;
    font-weight: 400;
    font-family: Inter;
    line-height: 156px;
}
.AIcheckbox {
    width: 10px;
    color: green !important;
    background-color: green !important;
}
.checkbox-select-data .MuiButtonBase-root .MuiButtonBase-root svg {
    font-size: 18px !important;
}
.AI-leade-checkbox-and-data-list svg {
    /* font-size: 15px !important; */
    height: 1em;
    width: 1em;
    margin-left: 10px;
    fill: #DEDFD5;
}
.AI-leade-checkbox-and-data-list_2 svg {
    /* font-size: 15px !important; */
    height: 1em;
    width: 1em;
    margin-left: 10px;
}
.AI-leade-checkbox-and-data-list label{
    display: flex;
    align-items: center;
}
.AI-Leadsfilter-items {
    padding: 4px 15px;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
    padding: 0px !important;
}
.menuitems:hover {
    background-color: unset !important;
}
.menuitems-last-filter :hover {
    background-color: unset !important;
}
.JScoreContainer div {
    padding: 0px 8px !important;
    margin: 0 !important;
    width: 100%;
}
.inner-reset-filter {
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: left;
    border: none;
    font-family: Inter;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.apply-hit-button {
    justify-content: center;
    margin: 0px 5px 0px 10px;
    display: flex;
    align-items: center;
}
.reset-hit-button {
    justify-content: center;
    margin: 0px 5px;
    height: 70px;
    display: flex;
    align-items: center;
}
.apply-hit-button div {
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    padding: 0px 11px;
    margin: 0 !important;
    border: 1px solid #DA291C
}
.reset-hit-button div {
    cursor: pointer;
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    padding: 0px 11px;
    margin: 0 !important;
    border: 1px solid #606060;
    height: 39px;
    border-radius: 5px;
}
.inner-apply-button-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 39px;
    border-radius: 4px;
}
.inner-reset-button-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 47px;
    border-radius: 4px;
}
.AileadsFilter-Apply-button {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    cursor: pointer;
    text-transform: none !important;
    border: none;
    color: #EA0234;
    width: auto;
    font-family: Inter;
}
.AileadsFilter-reset-button {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    cursor: pointer;
    text-transform: none !important;
    border: none;
    color: #606060;
    width: auto;
    font-family: Inter;
}
.apply-tick-icon {
    font-size: 14px !important;
    color: #EA0234;
    font-weight: 500;
}
.reset-tick-icon {
    font-size: 14px !important;
    color: #606060;
    font-weight: 500;
}
.outer-main-for-filtersand-buttons {
    text-transform: capitalize;
    margin-top: 1rem;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    padding-top: 50px;
    width: 100%;
}
.MyAiProfile-data-list-container .MuiFormControl-root .MuiInputBase-root svg {
    color: #323232;
}
.main-container-ofAi-leads-filters {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.leads_loc_filter_inner {
    border-bottom: 1px solid #DADADC;
    padding: 8px 0;
}
.location_filter_leads_cont {
    padding: 0 10px;
}
.location_filter_leads_cont .checkbox-select-data,.location_filter_leads_cont_2 .checkbox-select-data {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.location_filter_leads_cont .checkbox-select-data span svg {
/* font-size: 1.1rem !important; */
height: 1em;
fill:#DEDFE5;
}
.location_filter_leads_cont_2 .checkbox-select-data span svg {
/* font-size: 1.1rem !important; */
height: 1em;
}
.location_filter_leads_cont .checkbox-select-data .MuiTypography-root li,.location_filter_leads_cont_2 .checkbox-select-data .MuiTypography-root li{
font-weight: 700;

}
.location_filter_leads {
    padding: 0 20px;
}
.location_filter_checkbox {
    padding: 0 5px 0 10px !important;
}

.location_filter_checkbox_2 {
    padding: 0 5px 0 10px !important;
}
.location_filter_checkbox_22{
    color: green !important;
    padding: 0px 10px 0px 0px !important;
}
.location_filter_checkbox222{
    padding: 0px 10px 0px 0px !important;
    color: rgb(0 0 0 / 12%) !important;
}
.listing-select-data-leads span{
    font-size: 14px;
}
.location_filter_checkbox svg {
    height: 1em;
    /* margin-bottom: 2px; */
    fill: #DEDFE5;
}
.AI-leader-checkbox-and-data-list-outter{
    height: fit-content !important;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .apply-hit-button {
        justify-content: center;
        width: 13% !important;
        margin: 0px 10px;
        height: 70px;
        display: flex;
        align-items: center;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .apply-hit-button {
        justify-content: center;
        width: 22% !important;
        margin: 0px 10px;
        height: 70px;
        display: flex;
        align-items: center;
        float: left;
    }
    .outer-main-for-filtersand-buttons {
        text-transform: capitalize;
        margin-top: 1rem;
        margin-bottom: 8px;
        display: block;
        align-items: center;
        padding-top: 50px;
    }
}
.MyAiProfile-data-list-container .MuiFormControl-root .MuiInputBase-root {
    background-color: #DEDEDE;
    /* color: white; */
    height: 47px;
    line-height: 16px;
    color: #323232 !important;
}
.MyAiProfile-data-list-container em {
    font-weight: 600;
    font-size: 12px;
    font-family: Inter;
    font-style: unset !important;
}
.MyAiProfile-data-list-container .MuiFormControl-root .MuiSelect-select {
    margin: 0;
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.MyAiProfile-data-list-container .MuiFormControl-root {
    min-width: 100px;
}
.MyAiProfile-data-list-container {
    text-transform: capitalize;
    margin-top: 1rem;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    padding-top: 50px;
    justify-content: flex-end;
}
.setSelectProfile-ui {
    color: #323232;
}
.SelectedValue {
    color: #323232;
    font-size: 12px;
}
.inner-rest-button-container svg{
    height: 30px;
    width: 17px;
}
.AI-leader-checkbox-and-data-list{
    padding: 3px 10px;
}
