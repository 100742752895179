@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

* {
  padding: 0;
  margin: 0;
}

.graphup-and-line {
  width: 200px;
  height: 24px;
  align-items: center;
  display: flex;
}

.card-one-Total-AI-leads {
  padding: 15px;
  border-radius: 10px;
  /* width: 180px; */
  width: 220px;
  height: 161px;
  background-color: #ffffff;
}

.card-heading-main {
  text-transform: capitalize;
  color: #202224 !important;
  padding-bottom: 20px;
  letter-spacing: 0px;
  opacity: 0.7;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
}

.number-in-card {
  color: #202224;
  padding-bottom: 34px;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: left;
}

.number-in-card-last {
  color: #202224;
  padding-bottom: 26px;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.graph-ratio {
  padding-left: 8px;
  padding-right: 5px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #00b69b;
}

.graph-ratio-down {
  padding-left: 8px;
  padding-right: 5px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #f93c65;
}

.with-graph-ratio-and-info {
  color: #606060;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.agent-name {
  display: grid;
  gap: 8px;
  /* width: 236px; */
  height: 56px;
}

.hi-dash-name {
  font-weight: 700;
  line-height: 29.05px;
  text-transform: capitalize;
  font-family: Inter;
  font-size: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #101820;
}

.past-information {
  /* text-transform: capitalize; */
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #101820;
  opacity: 0.5;
}

.inner-cards-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.outer-cards-container {
  padding-top: 25px;
  padding-bottom: 35px;
}

.card-five-Total-AI-leads {
  padding: 15px 28px 15px 15px;
  border-radius: 10px;
  width: 220px;
  height: 161px;
  background-color: #ffffff;
}

.all-content-bg {
  background: #f4f5f9;
  height: 100%;
}

.graphup-and-line img {
  height: 16px;
  width: 16px;
}

.video-space {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.video_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
}

.bell-icon-box-video {
  position: absolute;
  background: #fff;
  box-shadow: 0px 0px 2px 0px #00000033;
  border-radius: 5px;
  padding:0 10px 10px 10px;
  top: 27px;
  right: 0;
  min-width: 150px;
  width: fit-content;
  z-index: 10000;
  overflow: visible;
  text-wrap-mode: nowrap;

}
.bellicons-notification-container:last-child .bell-icon-outer-video{
  padding-bottom: 0 !important;
}

.bell-icon-outer-video{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  width: 100%;
  padding: 10px;
}
.section-new-ai-lead, .video-space {
  position: relative;
  overflow: visible; 
}

.bellicon-p-search {
  color: #000 !important;
  font-size: 15px !important;
}

.video_img_drop {
  height: 25px;
  opacity: 0.6;
}

.video_drop_hover {
  cursor: pointer;
margin-bottom: 0 !important;
}

.video_drop_hover:hover .video_img_drop {
  opacity: 1;
}

.video_drop_hover:hover .bellicon-p-search {
  font-weight: 700 !important;
}

.Leades-filter-drop-down-button-main-container .bellicon-p-search {
  color: #000 !important;
  font-size: 15px !important;
  opacity: 1 !important;
  font-weight: 400 !important;
}

/* Mobile responsive  */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .agent-name {
    flex-direction: column !important;
    width: 100% !important;
    padding: 0 0 0 10px;
  }

  .inner-cards-container {
    flex-direction: column !important;
    align-items: center;
  }

  .card-one-Total-AI-leads {
    width: 95%;
  }

  .card-five-Total-AI-leads {
    width: 95%;
  }

  .kljdf {
    display: block;
  }
}

/* tab responsive  */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .card-one-Total-AI-leads {
    width: 220px;
  }

  .card-five-Total-AI-leads {
    width: 220px;
  }
}