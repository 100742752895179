.Glossary-card-outter{
    background-color: white;
    height: auto;
    padding: 20px;
}
.Glossary-heading {
    padding: 50px 0 20px;
}
.Glossary-card-Flex-container{
    display: flex;
    /* gap: 80px; */
    padding: 10px;
    align-items: center;
}
.Glossary-card-Flex-containerNEW{
    display: flex;
    /* gap: 80px; */
    padding: 10px;
}
.Glossary-underline{
    background-color: #D9D9D9;
    width: 100%;
    height: 1px;
}
.Glossary-cardmiddel{
    width: 50%;
}
.Glossary-cardmiddel p{
    padding: 15px 0 0;
}
.Glossary-cardmiddel h4{
    padding: 10px 0 0;
}
.Glossary-cardmiddeladdingnew{
    width: 50%;
}
.Glossary-cardmiddeladdingnew p{
    padding: 15px 0 ;
}
.Glossary-cardmiddeladdingnew h4{
    padding: 15px 0 ;
}
.Glossary-card-heading h1{
    font-size: 48px;
    font-weight: bold;
    line-height: auto;
    font-family: Inter;
}
.Glossary-card-heading{
    width: 10%;
}
.Glossary-card-sub-heading{
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.Glossary-card-middel-heading{
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 20px;
}
.main-div{
    width: 92%;
}
.JOI-suspect{
    font-size: 16px;
    font-weight: bold;
    width: 20%;
}
.Glossary-card--heading{
    width: 20%;
    display: flex;
    justify-content: flex-start;
}
.flex-alldaata{
    display: flex;width: 100%;
    gap: 30px;
    padding: 20px 0 0;
}
.JOI-Score-css{
    width: 50%;
}
.Glossary-card-middel-headingnew{
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
}
.Glossary-card-sub-headingtop-bottom{
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 100px;
}
.Glossary-card-sub-headingtop-bottomCompany{
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 164px;
}
.Glossary-card-last{
    width: 50%;
}
.Glossary-card-last p{
    padding: 10px 0;
    font-size: 16px;
    font-weight: normal;
}
.Glossary-card-lastf1data{
    width: 92%;
    padding-top: 15px;
}
.Glossary-inner-F1-score-flex{
    display: flex;
    gap: 20px;
}
.Glossary-inner-F1-heading{
    width: 20%;
}
.Glossary-inner-F1-heading h3 {
    padding: 10px 0;
}
.Glossary-inner-F1-innerdata{
    width: 50%;
}
.Glossary-inner-F1-innerdata  p {
    font-size: 16px;
    font-weight: normal;
}
.Glossary-card-lastf1data    p{
    padding: 10px 0;
    font-size: 16px;
    font-weight: normal;
}
.Glossary-card-last-neew{
    width: 50%;
}
.Glossary-card-last-neew p{ 
    padding: 10px 0;
    font-size: 16px;
    font-weight: normal;
}
.lastheadingdata{
    font-weight: bold;
    font-size: 16px;
}
.lastConnection{
    font-weight: bold;
    font-size: 16px;
    width: 20%;  
    
}
.Glossary-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}
.Glossary-button-container button{
    border: 1px solid #DA291C;
    color: #DA291C;
    border-radius: unset;
    font-weight: bold;
    font-size: 16px;
}
.Glossary-button-container button :hover{
    border: 1px solid #DA291C !important;
}