.CompanyProfileTabs-heading {
  color: #000000 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16.94px !important;
}

.Companydata .MuiTabs-scroller .MuiTabs-indicator {
  background-color: #DA291C !important;
}

.Companydata .MuiTabs-flexContainer .Mui-selected {
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.94px;
  height: 45px;
  text-transform: none;
  font-family: Inter !important;
  max-width: 100% !important;
}

.Companydata .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root {
  max-width: 100% !important;
  text-transform: none;
  font-family: Inter !important;
  background-color: #fff !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
}

.Companydata.MuiTabs-scroller .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: black !important;
}

.company-profile-main-tabs-data #simple-tabpanel-0 {
  background-color: #F5F6FA !important;
  padding: 0 10px;
}

.company-profile-main-tabs-data .MuiBox-root {
  padding-left: 20px;
}

.Leades-filter-inner-container-forCompanyProfileTabs {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.Leades-filter-inner-container-forCompanyProfileTabs button {
  background-color: #DA291C;
  font-size: 11px;
  font-family: Inter;
  font-weight: 600;
  width: 100px !important;
  height: 47px;
  display: flex;
  justify-content: space-between;
  padding: 10px, 17px, 10px, 17px;
}

.Leades-filter-inner-container-forCompanyProfileTabs button:hover {
  background-color: #BB291E;
  font-family: Inter;
}

.cp_outer_container_leads {
  background: #F5F6FA !important;
  padding: 82px 35px 0 30px;
}

.cp_inner_container_leads {
  background: #fff;
  border: 0.6px solid #D5D5D5;
  border-radius: 14px !important;
  /* padding: 20px; */
}

.CompanyProfile-flex-container {
  padding: 20px;
  width: 100%;
}

.company_bio_trio,
.CompanyProfile-infomation {
  padding: 0 15px
}

.company-profile-main-tabs-data .MuiBox-root {
  border-bottom: unset !important;
}

.Companydata {
  padding-top: 5px;
}

.CompanyProfile-infomation-main .MuiAccordion-root {
  box-shadow: unset !important;
  border-bottom: 1px solid #D5D5D5;
}

.company_tabs_data_cont {
  background-color: #F5F6FA !important;
  padding: 0 10px;
}

.crit_out {
  background-color: #fff;
}

.company_bio_trio {
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16.94px !important;
  text-align: left !important;
  color: #808080 !important;
}

.add-to-fav-org-cont {
  width: 106px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.add-to-fav-org {
  display: flex;
  justify-content: flex-end;
}