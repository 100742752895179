.CI-hd {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    text-align: left;
    color: #202224;
    ;
}
.CI-content {
    margin: 15px 0 25px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #666666;
    /* text-transform: lowercase; */
}
.CI-content::first-letter {
    text-transform: uppercase;
}
.CI-adding-contant {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
}
.CI-cards {
    width: 100%;
    height: 192px;
    gap: 0px;
    border-radius: 5px;
    border: 1px solid #999999;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    border-style: dashed;
}
.CI-cards-content {
    height: fit-content;
    width: 100%;
    gap: 0px;
    border-radius: 5px;
    border: 1px solid #999999;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
}
.CI-card-content {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
    color: #202224;
    margin-top: 10px;
}
.add-contents-cards {
    gap: 12px;
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
}
.add-contents-cards-nfeed {
    gap: 12px;
    display: flex;
    justify-content: space-between;
}
.dash-model-head {
    display: flex;
    padding: 15px;
    justify-content: space-between;
}
.dash-model-head h3 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    text-align: left;
}
.dash-cancel-save-btns {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 20px 15px;
}
.tp-box-content-dash-mod .css-19kzrtu {
    padding: 10px 30px !important;
}
.tp-search-conent-dash-mod .css-19kzrtu {
    padding: 10px 20px !important;
}
.searchicon-in-header-mod {
    height: 20px !important;
    width: 20px !important;
    color: #000000;
}
.asdasdasd .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    color: none !important;
    background-color: #F0F0EF !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #000000 !important;
    background-color: #F0F0EF !important;
}
.header-main-dashboard {
    width: 200px !important;
}
.result-tags-icon-comp {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #999999;
}
.result-date-icon-comp {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 4px 4px 0px #0000000F;
}
.checkbox-result-tag-comp {
    display: flex;
    gap: 5px;
    align-items: center;
}
.result-comp {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    display: flex;
    align-items: center;
}
.ai-tags-comp {
    border: 0.55px solid #7DCB93;
    background: #F0F0F0;
    width: 57px;
    height: 27px;
    border-radius: 16.5px;
    font-size: 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sorted-by-comp {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.73px;
    text-align: left;
}
.date-comp {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;
}
.copy-threedot-comp {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}
.threedot-icon-comp {
    color: #8D8D8D;
}
.joi-circle-text-comp {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px;
}
.jo-icon-comp {
    width: 50px;
    height: 50px;
    background: #323232;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 41px;
}
.jo-icon-comp p {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 27.84px;
    text-align: left;
}
.jobsohio-name-comp {
    text-transform: capitalize;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: left;
}
.today-comp {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;
}
.news-comp {
    font-family: Inter;
    font-size: 13px;
    color: #878b8f;
}
.news-comp a {
    color: #878b8f;
    text-decoration: none;
    padding-right: 5px;
}
.both-news-time-comp {
    display: flex;
    align-items: center;
    gap: 5px;
    /* justify-content: space-between; */
}
.content-pera-comp {
    padding: 0px 15px 15px;
}
.bold-content-pera-comp {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
}
.norm-content-pera-comp {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    padding: 8px 0px;
}
.million-reach-comp {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;
}
.ci-outer {
    display: flex;
    width: 100%;
    /* align-items: center; */
}
.ci-tabs {
    width: 30%;
    border-right: 1px solid #cdbdbd;
    height: auto;
}
.ci-tabs div {
    padding: 10px;
    border-bottom: 1px solid #cdbdbd;
    cursor: pointer;
}
.ci-tabs div:last-child {
    cursor: pointer;
    padding: 10px;
    border-bottom: none;
}
.ci-search {
    width: 70%;
}
.ci-search-inner {
    display: flex;
    justify-content: center;
    /* padding-top: 20px; */
    height: 175px;
}
.ci-selected {
    cursor: pointer;
    background-color: #F0F0F0;
    padding: 10px;
    border-bottom: 1px solid #cdbdbd;
    color: #000000;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
}
.ci-searchbar {
    position: relative;
}
.CI-adding-content {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #202224;
    text-transform: unset !important;
}
.Intersect-jb-nf-comp p {
    background: #F0F0F0;
    border: 0.55px solid #7DCB93;
    border-radius: 16px;
    padding: 2px 10px;
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
}
.Intersect-jb-nf-comp {
    flex-flow: wrap;
    display: flex;
    gap: 10px;
    padding-top: 10px;
}
.wrap-search-news {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-100%, -50%);
    width: 0px;
    height: 29px;
    /* background: #7c23b2; */
    line-height: 30px;
    padding-right: 38px;
    border-radius: 5px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
    transition: all 0.5s ease;
}
.input_search_news {
    border: 0;
    background: transparent;
    width: 0%;
    outline: none;
    font-size: 14px;
    transition: all 0.3s ease;
    position: relative;
}
.wrap-search-news img {
    position: absolute;
    right: 9px;
    top: 7px;
    font-size: 22px;
    cursor: pointer;
}
.wrap-search-news svg {
    position: absolute;
    right: 3px;
    top: 3px;
    font-size: 22px;
    cursor: pointer;
}
.wrap-search-news.active {
    width: 200px;
    padding-left: 8px;
    transition: all 0.5s ease;
    background: #eee;
}
.input_search_news.active {
    width: 100%;
    padding-left: 5px;
    transition: all 0.5s 0.8s ease;
}
.search_news_container {
    position: relative;
    display: block;
    height: 100%;
}
/* .hr-tag-ci {
    color: "#878b8f"
} */
.rest-cont:last-of-type hr {
    display: none;
}
.save-button-dash {
    background: #da291c !important;
    border-color: #da291c !important;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
}
.rest-cont:last-of-type hr {
    display: none;
}
.date-comp-dropdown {
    margin: 0px !important;
}
.date-comp-select .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0px !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none !important;
}
.date-comp-em {
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    line-height: 15.73px;
    text-align: left;
    font-style: initial;
}
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
    position: absolute;
    right: 20px !important;
    top: calc(50% - .5em);
    pointer-events: none;
    color: rgba(0, 0, 0, 0.54);
}
.css-bpeome-MuiSvgIcon-root-MuiSel {
    right: 20px !important;
}
.css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
    right: 20px !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    font-family: Inter !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}
.news-events-search-ci {
    padding: 10px 30px;
    cursor: pointer;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;
}
.people-search-ci {
    padding: 10px 30px;
    cursor: pointer;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;
}
.organization-search-ci {
    padding: 10px 30px;
    cursor: pointer;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;
}
.ci-search-news-events {
    overflow-y: scroll;
    height: 70vh;
    width: 70%;
}
.news-event-search-modal {
    display: flex;
    justify-content: center;
    padding: 10px 30px;
}
.news-modal-body {
    display: flex;
    justify-content: center;
}
.dash-cancel-save-btns .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 23px;
    text-align: center;
}
.card-heading-top {
    font-size: 16px;
    font-family: inter;
    font-weight: 600;
    text-transform: capitalize;
}
.news-event-grid {
    display: grid;
    gap: 10px;
}
.news-event-modal-text {
    padding: 0px 35px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.news-event-modal-item {
    display: flex;
    gap: 8px;
    padding: 0px 35px;
    align-items: center;
}
.custom-checkbox {
    cursor: pointer;
    width: 16px;
    height: 16px;
    accent-color: #2e7d32;
  }
  
  .custom-checkbox:checked {
    background-color: #2e7d32;
    border-color: #2e7d32;
  }