@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

* {
  padding: 0px;
  margin: 0px;
}

.login-section-main {
  width: 100%;
  display: block;
  background-color: #fff;
  padding: 40px 0px;

}

.logo-section-login {
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo-login {
  width: 165px;
  height: 91px;
  margin-bottom: 25px;
}

.login-card-main {
  width: 47%;
  /* min-height: 47vh; */
  max-height: auto !important;
  border-radius: 16px;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 36px 68px 0px #0000001a;
  padding: 40px 20px;
}

.section-login-after-main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 80vh;
  max-height: auto !important;
}

.login-card-content-section {
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
}

.heading-content-section {
  width: 100%;
  display: block;
}

.heading-content-section h2 {
  font-size: 28px;
  color: #202224;
  line-height: 33.89px;
  font-weight: 600;
  font-family: Inter;
  margin-bottom: 20px;
}

.heading-content-section p {
  font-size: 18px;
  font-weight: bold;
  line-height: 21.78px;
  color: #202224;
  font-family: Inter;
  opacity: 80%;
}

.email-address-input {
  width: 94%;
  height: 56px;
  border: 1px solid #dedfe5;
  border-radius: 5px;
  margin-top: 20px;
  padding: 0px 16px;
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  font-family: Inter;
}

.email-address-input:focus-visible {
  outline: none !important;
}

.labels-login {
  font-size: 18px;
  font-weight: 600;
  font-family: Inter;
  line-height: 21.78px;
  color: #202224;
  opacity: 80%;
}

.email-address-section {
  width: 100%;
  display: block;
  margin-bottom: 40px;
}

.keep-logged-in-section {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.keep-logged-in-section label {
  font-size: 18px;
  font-weight: 600;
  font-family: Inter;
  line-height: 21.78px;
  color: #202224;
  opacity: 60%;
  margin-left: 8px;
}

.password-section-login {
  margin-bottom: 30px;
}

.keep-logged-in-section input {
  width: 16px;
  height: 16px;
}

.login-button-section {
  width: 100%;
  display: flex;
  justify-content: center;
}

.login-button-section button {
  width: 60%;
  height: 56px;
  background-color: #da291c;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  opacity: 90%;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.07px;
  font-family: Inter;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}
.disablebutton{
  width: 60%;
  height: 56px;
  background-color: #da291c;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  opacity: 90%;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.07px;
  font-family: Inter;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5 !important;
  margin-top: 12px;

}

.separator {
  text-align: center;
  margin: 30px 0;
  border-bottom: 0.1rem solid #dedfe5;
  position: relative;
}

.separator span {
  display: inline-block;
  padding: 0 10px;
  background-color: #fff;
  position: absolute;
  top: -8px;
  font-weight: 600;
  font-size: 14px;
  opacity: 90%;
}

.login-button-section-okta {
  width: 100%;
  display: block;
}

.login-button-section-okta button {
  width: 100%;
  height: 56px;
  background-color: #de3e331f;
  color: #de3e33;
  border: none;
  outline: none;
  border-radius: 5px;
  opacity: 90%;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.07px;
  font-family: Inter;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Login-screencheckbox{
  display: flex;
  justify-content: left;
  align-items: center;
}
.Useragreement{
  display: flex;
  justify-content: left;
  align-items: center;
}
.hidecheckbox{
  visibility: hidden !important;
}
.Login-screencheckbox p{
  margin: 0;
  font-weight: 500 !important;
  font-size: 16px !important;
}
.Login-screen-agree-heading{
  font-size: 16px !important;
  font-weight: 400;
  line-height: 21.78px;
  color: #202224;
  font-family: Inter;
  opacity: 80%;
  padding-left: 12px !important;
  margin-bottom: 0px !important;

}
.login-screen-hide-data p{
  font-size: 16px;    
  font-weight: 400;
  line-height: 21.78px;
  color: #202224;
  padding: 6px 10px ;
  font-family: Inter;
  opacity: 80%;
  text-align: left;
  /* line-height: 10px; */
  margin-bottom: 0px !important;
}
.login-page-reacd-more{
  cursor: pointer;
  font-size: 16px;    
  font-weight: 400;
  font-family: Inter;
  /* color: blue; */
  color: #0096ff;

}
.LoginVerify-modal-main .MuiBox-root  {
border: none !important;
outline: none !important;
border-radius: 15px !important;
height: 60% !important;
padding: 15px !important;
width: 30% !important;

}
.Back-to-login-page{  
  border: 1px solid #da291c !important;
  color: #da291c !important;
  margin-top: 20px !important;
  text-transform: capitalize !important;
  width: 60% !important;
}
.Modal-button-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Back-to-login-page:hover{
  background: white !important;
}
.Modal-image{
  width: 50%;
  display: flex;  
  justify-content: center;
  align-items: center;  
  height: 60%;
}
.Modal-image-container{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.Massage-for-theuser{
  font-size: 18px !important;
  font-weight: 500 !important;
  text-align: center;
}
.Modal-containt-container{
  margin-top: 50px;
}
.contact-us-modal{
  width: 100%;

}
.contact-us-modal  .MuiBox-root  {
  width: 55% !important;
  height: auto !important;
  border-radius: 20px !important;
  border: none  !important;
  outline: none;
}
.Contact-us-modal-flex-container{
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 30px;
  padding-bottom: 10px !important;
  padding-top: 25px !important;

}
.Modal-contactus-Drop-downs .MuiFormControl-root {
  margin: 0 !important;
}
.Modal-contact-inputs{
  width: 30% !important;
}
.Contact-us-flex-for-drop-downs{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px !important;
  gap: 10px ;
}
.Modal-contactus-Drop-downs{
  width: 50% !important;
}
.Modal-contactus-Drop-downs .MuiFormControl-root {
  width: 100% !important;
}
.Contact-us-container-fortextarea{
  padding-bottom: 20px;
}
.Contact-us-container-fortextarea label{   
   font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
}
.Modal-contactus-Drop-downs label{
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
}
.Modal-contact-inputs label{
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
}
.Contact-heading-on-modal{
  font-size: 20px;
  color: #101820;
  font-weight: 500;
}
.ContactUs-modal-submit-buttton{
  background: #da291c !important;
  width: 20%;
  padding: 10px;
  gap: 10px;
}
.ContactUs-modal-submit-buttton svg{
  transform: rotate(-89deg);
  font-size: 18px;
}
.Contact-us-modal-inner-heading{
  font-weight: 500 !important;
    line-height: 19px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    color: #101820 !important;
    font-size: 16px !important;
    opacity: 0.5 !important;
}
@media screen and (max-width: 767px) and (min-width: 320px) {
  .login-card-main {
    width: 259px;
    height: 490px;
    border-radius: none;
    background-color: none;
    border: none;
    box-shadow: none;
    padding: none;
  }
  .heading-content-section h2 {
    text-align: center;
    font-size: 26px;
  }

  .heading-content-section p {
    text-align: center;
    font-size: 15px;
  }

  .email-address-input {
    width: 88%;
    font-size: 16px;
  }
  .email-address-section {
    margin-bottom: 25px;
  }
  .labels-login {
    font-size: 16px;
  }
  .keep-logged-in-section label {
    font-size: 16px;
  }
  .login-card-main {
    width: 90%;
  }
  .logo-login {
    width: 146px;
    height: 77px;
    margin-bottom: 12px;
  }
  .login-button-section button {
font-size: 18px;
}
}

/* tab responsive  */
@media screen and (max-width: 1024px) and (min-width: 768px) {

.logo-login {
  width: 132px;
  height: 65px;
  margin-bottom: 84px;
}

}