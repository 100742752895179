.track_head {
    padding-bottom: 15px !important;
}
.table-tracking {
    padding: 20px 20px 0px 20px;
}
.tracking_label {
    font-size: 15px;
    font-weight: 600;
    padding: 20px 0px 5px 0;
}
.track_inner_p {
    padding: 35px 0 0;
    font-size: 14px;
    display: flex;
    display: flow;
}
.track_inner_p img{
margin: -5px 5px;
}
.track-full-searchbar {
    border: 1px solid #DEDFE5;
    background-color: #DEDFE5 !important;
    border-radius: 5px;
    padding: 2px 12px !important;
    height: 44px;
    box-shadow: none !important;
    position: relative;
}
.Autocompletedropdown-track{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    background-color: white;
    /* padding: 10px; */
    position: absolute;
    top: 45px;
    width: 420px;
    left: 0px;
    max-height: 48vh;
    min-height: 2vh !important;
    overflow-y: scroll;
    z-index: 1;
}
.cursor_pointer{
    cursor: pointer;
}
.addIconGreen{
    color: green !important;
}