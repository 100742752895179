@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.p-details-main {
  margin: 108px 0px 22px 0px;
  border: 0.3px solid #B9B9B9;
  border-radius: 15px;
  padding: 15px;
  background-color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.salesforce-prospect {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: 20px;
}

.border-change-profile {
  position: relative;
  font-size: 13px;
  width: 35px;
  height: 35px;
  border: 2px solid #ebedf5 !important;
  color: #003057;
  background-color: #f5f6fa;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.salesforce-plus-names {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.name-profile-mention-prospects {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 5px;
  text-transform: capitalize;
}

.company-profile-mention {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 5px;
  /* text-transform: capitalize;
  text-transform: lowercase; */
}

.company-profile-mention-b1 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 5px;
  text-transform: capitalize;
}

.company-profile-mention-main {
  display: flex;
  justify-content: space-between;
  width: 70%;
}

.profiles-contents {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.profiles-contents-last {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.pro-pro-span {
  width: 50px;
}

.com-details-profile {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.15px;
  text-align: left;
  display: flex;
  gap: 5px;
}

.com-details-profile p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 5px;
  /* text-transform: lowercase; */
}

.com-details-profile-b3 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.15px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.exp-main-container {
  width: 100%;
  background-color: #ffffff;
  padding: 15px;
  border: 0.3px solid #B9B9B9;
  border-radius: 10px;
}

.exp-main-container-fit {
  width: 100%;
  background-color: #ffffff;
  padding: 15px;
  border: 0.3px solid #B9B9B9;
  border-radius: 10px;
  height: fit-content;
}

.exp-heading-profile p {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
}

.ford-comp {
  text-transform: capitalize;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.95px;
  text-align: left;
}

.yearly-mentioned {
  /* font-family: Open Sans; */
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  display: flex;
  gap: 5px;
}

.position-analyst {
  /* font-family: Open Sans; */
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 34px;
  text-align: left;
  color: #202224;
  text-transform: capitalize;
}

.exp-table-dot {
  background-color: #D9D9D9;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.exp-line {
  border-left: 1px solid #D9D9D9;
  height: 50%;
  /* height: 100%; */
  position: relative;
  top: 0;
  right: 5px;
}

.exp-table-dot-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  top: 13px;
  position: relative;
}

.exp-position-container {
  display: flex;
}

.exp-position-content {
  margin-left: 10x;
}

.school-management {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.36px;
  text-align: left;
  text-transform: capitalize;
}

.buss-management {
  display: flex;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24.5px;
  text-align: left;
}

.exp-heading-profile {
  padding-bottom: 12px;
}

.b2-block-second {
  padding-bottom: 15px;
}

.prospect-aipath {
  background-color: #FFFFFF;
  border: 1px solid #CBCBCB;
  border-radius: 5px;
  padding: 10px;
}

.newsfeed-prospect {
  background-color: #FFFFFF;
  border: 1px solid #CBCBCB;
  border-radius: 5px;
  padding: 10px;
  height: 30vh;
}

.h3 {
  text-transform: capitalize;
  color: #101820;
}

.newsfeed-plus-aipath {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 25px;
  gap: 20px;
}

.comp-newsfeed {
  width: 50%;
}

.comp-aipath {
  /* width: 100%; */
  width: 50%;
}

.comp-aipath-heading {
  padding: 15px 5px;
}

.comp-newsfeed h3 {
  padding-bottom: 15px;
}

.comp-aipath h3 {
  padding-bottom: 15px;
}

/* ......... Ai Path ................  */
.innerData_ai_cont-profile {
  width: 50%;
}

.remove-prospect-aipath {
  font-size: 11px;
}

.dlt-prospect-aipath {
  height: 13px;
  width: 13px;
}

.Siderbar--inner-container-profile h3 {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
  font-family: Inter;
  color: #101820;
}

.submain-container-profile {
  padding: 5px;
  overflow-y: scroll;
  height: 73vh;
  margin: 2px 2px;
  z-index: 1;
}

.submain-container-profile-pdf {
  padding: 5px;
  margin: 2px 2px;
  z-index: 1;
}

.siderbar-image-container-profile {
  padding: 5px 5px;
}

.sider-var-list-data-button-profile {
  z-index: 999;
  text-transform: capitalize;
  cursor: pointer !important;
  background: #F5F6FA;
  border: 1px solid #DEDFE5;
  color: black;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: auto;
  height: auto;
  border-radius: 4px;
  font-weight: 600;
  padding: 5px;
}

.identity-name-profile {
  font-size: 10px;
  text-transform: capitalize !important;
}

.container-of-joint-line-of-node2-profile {
  position: relative;
  display: block;
  width: 100%;
  top: -22px;
  height: 105px;
}

.dates-company-cnt {
  padding: 15px 5px;
}

.dot-prospectexp {
  font-size: 34px;
  position: relative;
  top: -10px
}

.decisionMakerImageAi-Decisionmaker-pd-profile {
  height: 18px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: 20px;
}

.Decision-maker-user-name-profile {
  border: 2px solid #da291c;
  color: #003057;
  /* width: 35px;
  height: 35px; */
  padding: 5px;
  background-color: #f5f6fa;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
}

.Decision-maker-user-name-profile {
  position: relative;
}

/* Prevent breaking within specific elements */
.child-section-of-everypage,
.newsfeed-plus-aipath,
.prospect-details-section {
  page-break-inside: avoid !important;
}

.social-media-icons {
  display: flex;
  align-items: center;
  gap: 5px;
}