* {
  padding: 0px;
  margin: 0px;
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");


.leads-profile-table-main {
  border-radius: 14px !important;
  box-shadow: none !important;
  border: 1px solid #d5d5d5;
}

.cell-profile-name {
  text-transform: capitalize;
  width: 30%;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16.94px !important;
  font-family: Inter !important;
}

.cell-created-on {
  text-transform: capitalize;
  width: 30%;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16.94px !important;
  font-family: Inter !important;
}

.cell-status {
  text-transform: capitalize;
  width: 30%;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16.94px !important;
  font-family: Inter !important;
}
.default-button-profile {
  color: #ffffff;
  background-color: #003057;
  height: 33px;
  width: 84px;
  border-radius: 4px;
  border: none;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: center;
}
.open-button-profile {
    color: #ffffff;
    background-color:#0BB83C;
    ;
    height: 33px;
    width: 84px;
    border-radius: 4px;
    border: none;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: center;
  }
.downicon-table-profile{
    color: #da291c;
    font-size: 22px;
}
.status-icon-profile{
    display: flex;
    gap: 10px;
    align-items: center;
}
.threeDotIcon-table-profile{
    border: 1px solid #d5d5d5 !important;
    border-radius: 9px !important;
    width: 36px;
    height: 36px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: #da291c;
    background-color: #FAFBFD;
    cursor: pointer;
}
.three-dot-cell {
  gap: 9px;
  display: flex !important;
}
.table-row-leadsprofile{
  cursor: pointer !important;
}
.leads-profilethead tr th{
  text-align: center;
  padding: 16px 20px !important;
}
.table-row-leadsprofile th{
  text-transform: capitalize;
  padding: 20px !important;
  text-align: center;
}
.table-row-leadsprofile td{
  text-transform: capitalize;
  padding: 20px !important;
  text-align: center;
}