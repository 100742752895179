.DecisionMaker-table-main {
  border: 0.6px solid #D5D5D5;
  border-radius: 14px !important;
  box-shadow: none !important;
  font-family: Inter;
}

.DecisionMaker-table thead tr {
  background-color: #fcfdfd;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.DecisionMaker-table .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
  /* padding-left: 40px; */
  border-bottom: none !important;
  text-align: center;
}

.DecisionMakecheckbox-row-table {
  width: 5% !important;
}

.DecisionstableStrength,
.prospects-row-tableDetails {
  text-transform: capitalize;
  /* width: 12%; */
  text-align: center !important;
  width: 16%;
}

.employee-row-tableCompany {
  width: 10% !important;
  text-align: center !important;

}

.Decisions-row-tableName {
  width: 10%;
  text-align: center !important;
}

.industry-row-tableStatus {
  width: 100px !important;
  text-align: center !important;
}

.DecisionstableStrengthnewclass {
  width: 100px !important;
  text-align: center !important;
}

/* .prospects-row-tableDetailsLast-TH-of-decisionmaker{
  text-transform: capitalize;
  width: 5% !important;
  text-align: center !important;
} */
.DecisionstableStrength-strength {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .table-cellhandleRightsidebar {
  margin: 20px 0 0 80px;
} */
.DecisionmakerScore {
  background-color: #0eb93e;
  color: white;
  padding: 4px, 8px, 4px, 8px;
  border-radius: 26px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
}

/* .company-name-countrylastname{
  float: left;
} */
.Decision-maker-userTeblesell {
  min-width: 12rem;
  /* position: relative; */
}

.Decision-maker-user-name-main-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.letter-heading {
  text-transform: uppercase !important;
}

.Decision-maker-user-name {
  position: relative;
  text-transform: capitalize;
  border: 2px solid #da291c;
  color: #003057;
  width: 46px;
  height: 46px;
  background-color: #f5f6fa;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}

.decisionMakerImageAi-Decisionmaker {
  height: 25px;
  border-radius: 50%;
  position: absolute;
  top: -7px;
  left: 28px;
}

.email-and-other-info-for-Prospects {
  position: absolute !important;
  display: flex;
  top: 23px !important;
  gap: 32px;
}

.button-collapse-table svg {
  font-size: 1rem;
  font-weight: 700;
}

.DecisionMaker-table-main tr {
  border-bottom: 0.6px solid #dedfe5;
}

.Suspect-table-data {
  text-transform: capitalize;
  display: flex;
  justify-content: center;
}

.Suspect-table-data svg {
  color: #da291c;
}

.DecisionMakercheckbox-row-table input {
  height: 16px;
  width: 16px;
  border: 1px solid red;
}

.score-pera-tag {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
}

.DecisionMaker-table .MuiTable-root .MuiTableBody-root .MuiTableRow-root:last-child {
  border-bottom: unset;
}

.DecisionMaker-table .MuiTableBody-root .MuiTableRow-root:last-child {
  border: none !important;
}

.Decision-maker-user-namenoborder {
  color: #003057;
  width: 46px;
  height: 46px;
  background-color: #F5F6FA;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}

.hide-image {
  display: none;
}

.Decisionstable {
  text-transform: capitalize;
  width: 12%;
}

.name-and-title-text {
  width: 100%;
}

.fullnameofuser {
  display: flex;
  gap: 5px;
}

.annual-revenue-table {
  text-transform: capitalize;
  text-align: left;
}

.joi-strength-control {
  display: flex;
  justify-content: center;
}

.after-company-name-country-fordecisionmaker {
  display: flex;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  color: #202224;
  opacity: 0.5;
  margin: 0;
  font-size: 14px;
  color: #333;
}

.prospect-Name-and-Title-propect {
  text-align: left;
  /* padding-left: 21px; */
}

.table-cell-of-contact-details-dropdown-th-prospect {
  text-align: center;
  position: unset !important;
  /* display: flex !important; */
  justify-content: center !important;
  align-items: center !important;
  width: 100px !important;
  /* width: 50%; */
}

.industry-text-dropdown-head-main-prospect {
  text-align: center;
  text-transform: capitalize;
  width: 109px !important;
}

.prospects-row-tableDetailsLast-TH-of-decisionmaker {
  width: 10%;
}

.prospects-row-tableDetails-cd {
  width: 100px !important;
}

.Decision-maker-userTeblesell_row {
  min-width: 160px !important;
  width: 160px !important;
}

.favorite_col_star {
  padding: 0 !important;
}

.Com-details-prospect {
  text-align: center;
  display: flex;
  justify-content: center;
}

.DecisionMaker-table .Decisions-row-tableName-prospect {
  padding: 0px 0px 0px 15px !important;
  position: relative;
  width: 1% !important;
}
.DecisionMaker-table .Decisions-row-tableName-prospect-sync-icons {
  padding: 0px 0px 0px 15px !important;
  position: relative;
  width: 5% !important;
}
.email-and-other-info-prospect {
  position: absolute !important;
  display: flex;
  gap: 32px;
  top: -20px;
  bottom: 0;
}

.annual-row-tableIndustry {
  width: 12% !important;
  text-align: center !important;
}

.button-collapse-table-propect {
  width: 10%;
  margin-left: 0px !important;
  /* border: 1px solid #d5d5d5 !important; */
  border-radius: 9px !important;
  width: 36px;
  height: 36px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.button-collapse-table-propect svg {
  font-size: 1rem;
  font-weight: 700;
}

.table-cellhandleRightsidebar-prospect {
  width: 10%;
}

.css-1ex1afd-MuiTableCell-root {
  padding: 15px !important;
}

.job-title-container {
  position: relative;
  display: flex;
}

.tooltip-container-jobtitle {
  position: relative;
}
.tooltip-highlights-jobtitle {
  visibility: hidden;
  background-color: #ffffff;
  color: #000000;
  text-align: left;
  padding: 5px;
  position: absolute;
  left: 50%;
  text-transform: capitalize;
  transform: translateX(-50%);
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
  min-width: 150px; 
  max-width: 300px; 
  width: fit-content;
  height: auto;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-family: Inter;
  font-weight: 400;
  line-height: 16.94px;
  padding: 5px;
  font-size: 14px;
  border-radius: 4px;
}

.job-title-container:hover .tooltip-highlights-jobtitle {
  visibility: visible;
  opacity: 1;
}

/* .tooltip-highlights-jobtitle {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px;
  position: absolute;
  left: 55%;
  text-transform: capitalize;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.job-title-container:hover .tooltip-highlights-jobtitle {
  visibility: visible;
  opacity: 1;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #ffffff;
  font-family: Inter;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 2px 5px;
  color: #000000;
  font-size: 14px;
} */

.hover-container-prospects {
  position: relative;
  display: inline-block;
}

.hover-content-prospects {
  position: absolute;
  top: -20px;
  transform: translateX(-50%);
  background: white;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 10;
  white-space: nowrap;
  display: none;
}

.hover-container-prospects:hover .hover-content-prospects {
  display: block;
}

.no-update-prospects {
  left: -210px;
}

.has-update-prospects {
  left: -100px;
}

.data-sync-c-rate-prospects {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  display: grid;
}

.datasync-line-prospects {
  color: #000000 !important;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;

}

.datasync-line-data-prospects {
  color: #000000 !important;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
  padding-left: 5px;
}

.datasync-line-c-score-prospects {
  display: flex;
  align-items: center;
}
.up-down-arrows-sorting{
  font-size: 10px !important;
  cursor: pointer !important;
}
.sync-sorting-img-d-maker {
  background-color: #DA291C;
  padding: 5px;
  border-radius: 11px;
  cursor: pointer;
  font-size: 10px;
  cursor: pointer;
  font-size: 20px !important;
  color: #ffffff;
}
.confience-score-icons{
position: relative;
display: flex;
justify-content: center;
}